import { generalThemeSettings } from './generalThemeSettings';

const green = {
  25: '#F6FEF9',
  50: '#ECFDF3',
  100: '#DCFAE6',
  200: '#A9EFC5',
  300: '#75E0A7',
  400: '#47CD89',
  500: '#17B26A',
  600: '#079455',
  700: '#067647',
  800: '#085D3A',
  900: '#053321',
};

const orange = {
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E',
};

export const lightPaletteText = {
  primary: '#101828',
  secondary: '#344054',
  secondary_hover: '#182230',
  tertiary: '#475467',
  disabled: '#667085',
  success_primary: '#079455',
  success_secondary: green[700],
  error_primary: '#D92D20',
  warning_primary: orange[600],
  warning_secondary: orange[700],
  placeholder: '#667085',
  brand_secondary: '#635235',
  text_quarterary: '#667085',
  gray_700: '#344054',
  brand_700: '#635235',
  brand_tertiary: '#7F6944',
  tertiary_hover: '#344054',
  secondary_disabled: '#34405499', // 60% opacity
};

export const darkPaletteText = {
  primary: '#F5F5F6',
  secondary: '#CECFD2',
  secondary_hover: '#ECECED',
  tertiary: '#94969C',
  disabled: '#85888E',
  success_primary: '#47CD89',
  success_secondary: green[300],
  error_primary: '#F97066',
  warning_primary: '#FDB022',
  placeholder: '#85888E',
  warning_secondary: orange[300],
  brand_secondary: '#CECFD2',
  text_quarterary: '#94969C',
  gray_700: '#CECFD2',
  brand_700: '#CECFD2',
  brand_tertiary: '##94969C',
  tertiary_hover: '#CECFD2',
  secondary_disabled: '#cecfd299', // 60% opacity
};

const themesConfig = {
  goldLight: {
    palette: {
      mode: 'light',
      divider: '#e2e8f0',
      text: lightPaletteText,
      primary: {
        light: '#B2A186',
        main: '#8C734B',
        dark: '#635235',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        light: '#ffecc0',
        main: '#FFBE2C',
        dark: '#ff9910',
        contrastText: lightPaletteText.primary,
      },
      background: {
        paper: '#FFFFFF',
        hover: '#F9FAFB',
        warning_primary: orange[50],
        primary: '#FFF',
        primary_hover: '#F9FAFB',
        secondary: '#F9FAFB',
        secondary_subtle: '#FCFCFD',
        buttonBaseHover: '#F5F5F5',
        overlay: '#0C111D',
        secondary_hover: '#F2F4F7',
        gray_50: '#F9FAFB',
        brand_200: '#F4F1ED',
        brand_50: '#F4F1ED',
        disabled: '#F2F4F7',
        gray_200: '#EAECF0',
        disabled_subtle: '#F2F4F7',
        brand_solid: '#7F6944',
        quarterary: '#EAECF0',
        primary_alt: '#FFF',
      },
      foreground: {
        primary_fg: '#101828',
        secondary: '#344054',
        brandPrimary: '#7F6944',
        navigation: {
          "nav-item-button-icon-fg": "#667085"
        },
        quarterary: "#667085",
        quinary: '#98A2B3',
        disabled: '#98A2B3',
        white: '#FFFFFF',
        senary: '#D0D5DD',
      },
      utility: {
        warning_50: '#FFFAEB',
        utility_gray_50: '#F9FAFB',
        utility_gray_200: '#EAECF0',
        utility_gray_400: '#98A2B3',
        utility_gray_500: '#667085',
        utility_gray_700: '#344054',
        brand_300: '#B2A186',
        brand_400: '#A38F6F',
        brand_600: '#7F6944',
        error_50: '#FEF3F2',
        error_200: '#FECDCA',
        error_700: '#B42318',
        brand_500: '#8C734B',
        brand_50: '#F4F1ED',
        brand_200: '#CABFAC',
        brand_700: '#635235',
        gray_blue_50: '#F8F9FC',
        gray_blue_700: '#363F72',
        pink_50: '#FDF2FA',
        pink_700: '#C11574',
        blue_50: '#EFF8FF',
        blue_700: '#175CD3',
        indigo_50: '#EEF4FF',
        indigo_700: '#3538CD',
        brand_primary: '#7F6944',
      },
      border: {
        primary: '#D0D5DD',
        main: '#D0D5DD',
        secondary: '#EAECF0',
        disabled: '#EAECF0',
        tertiary: '#F2F4F7',
        error: '#FDA29B',
        error_solid: '#D92D20',
        success: green[200],
        warning: orange[200],
        brandSolid: '#7F6944',
        brandSolidAlt: '#7F6944',
        brand_primary_alt: '#7F6944',
        brand_200: '#CABFAC',
        brand: '#b2a186',
        gray_200: '#EAECF0',
        avatarContrast: '#00000014',
        disabled_subtle: '#EAECF0',
        transparent: 'transparent',
        border_disabled: '#D0D5DD'
      },
      error: {
        primary: '#D92D20',
        light: '#FEF3F2',
        main: '#D92D20',
        // dark: '',
      },
      warning: {
        light: '#FFFAEB',
        main: orange[500],
        // dark: ',
      },
      success: {
        light: green[50],
        main: green[500],
        // dark: '',
      },
      button: {
        primary_bg: '#7F6944',
        primary_bg_hover: '#635235',

        secondaryColor_bg_hover: '#F4F1ED',

        secondary_bg: '#FFFFFF',
        secondary_bg_hover: '#f9fafb',

        primary_fg: '#FFFFFF',
        
        secondary_fg: '#344054',
        secondaryGray_fg: '#344054',
        secondaryGray_fg_hover: '#182230',
        
        tertiary_fg: '#635235',
        tertiary_fg_hover: '#4d3f29',
        
        tertiaryGray_fg: '#475467',
        tertiaryGray_fg_hover: '#344054',

        brandPrimary_fg: '#7F6944',

        tertiaryGray_bg: '#f9fafb',
        tertiaryGray_bg_hover: '#f9fafb',

        secondary_border: '#d0d5dd',

        secondaryGray_border: '#d0d5dd',
        secondaryGray_bg_hover: '#f9fafb',

        secondaryColor_border: '#b2a186',
        secondaryColor_hover: '#f4f1ed',

        secondaryColor_fg: '#635235',
        secondaryColor_fg_hover: '#4d3f29',

        primary_border: '#7F6944',
        primary_border_hover: '#635235',
        
        tertiaryColor_fg: '#635235',
        tertiaryColor_fg_hover: '#4d3f29',
        tertiaryColor_bg_hover: '#f4f1ed',
        
        lightGray_fg: '#344054',
        lightGray_bg: '#EAECF0',
        lightGray_bg_hover: '#d0d5dd',
        lightGray_border: '#EAECF0',
        lightGray_border_hover: '#d0d5dd',
        bg_hover: '#f9fafb',
      },
      breadcrumbs: {
        iconFG: '#667085',
        brand_fg_hover: '#635235',
        brand_bg_hover: '#f4f1ed',
        text_fg: '#475467',
        bg_hover: '#f9fafb',
        fg_hover: '#344054',
      },
      sidenavBackgroundColor: '#0C111D',
    },
    ...generalThemeSettings,
  },
  goldDark: {
    palette: {
      mode: 'dark',
      divider: 'rgba(241,245,249,.12)',
      text: darkPaletteText,
      primary: {
        light: '#B2A186',
        main: '#8C734B',
        dark: '#635235',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        light: '#B8E1D9',
        main: '#129B7F',
        dark: '#056D4F',
        contrastText: darkPaletteText.primary,
      },
      background: {
        paper: '#0C111D',
        hover: '#1F242F',
        main: '#333741',
        primary: '#0C111D',
        primary_hover: '#1F242F',
        secondary: '#161B26',
        warning_primary: orange[500],
        secondary_subtle: '#161B26',
        buttonBaseHover: '#161A26',
        overlay: '#1F242F',
        secondary_hover: '#1F242F',
        gray_50: '#1F242F',
        brand_200: '#8C734B',
        brand_50: '#8C734B',
        disabled: '#1F242F',
        gray_200: '#CECFD2',
        disabled_subtle: '#161B26',
        brand_solid: '#7F6944',
        quarterary: '#333741',
        primary_alt: '#161B26',
      },
      foreground: {
        primary_fg: '#FFFFFF',
        secondary: '#CECFD2',
        brandPrimary: '#8C734B',
        navigation: {
          "nav-item-button-icon-fg": "#94969c"
        },
        quarterary: "#94969C",
        quinary: '#85888E',
        disabled: '#85888E',
        white: '#FFFFFF',
        senary: '#61646C',
      },
      utility: {
        warning_50: '#4E1D09',
        utility_gray_50: '#161B26',
        utility_gray_200: '#333741',
        utility_gray_400: '#61646C',
        utility_gray_500: '#85888E',
        utility_gray_700: '#CECFD2',
        brand_300: '#635235',
        brand_400: '#7F6944',
        brand_600: '#A38F6F',
        error_50: '#55160C',
        error_200: '#912018',
        error_700: '#FDA29B',
        brand_500: '#8C734B',
        brand_50: '#2E2519',
        brand_200: '#4D3F29',
        brand_700: '#B2A186',
        gray_blue_50: '#101323',
        gray_blue_700: '#B3B8DB',
        pink_50: '#4E0D30',
        pink_700: '#FAA7E0',
        blue_50: '#102A56',
        blue_700: '#84CAFF',
        indigo_50: '#1F235B',
        indigo_700: '#A4BCFD',
        brand_primary: '#8C734B',
      },
      border: {
        primary: '#333741',
        main: '#333741',
        disabled: '#333741',
        secondary: '#1F242F',
        tertiary: '#1F242F',
        error: '#F97066',
        error_solid: '#F04438',
        success: green[800],
        warning: orange[800],
        brandSolid: '#8C734B',
        brandSolidAlt: '#333741',
        brand_primary_alt: '#CECFD2',
        brand_200: '#4D3F29',
        brand: '#a38f6f',
        gray_200: '#333741',
        avatarContrast: '#ffffff1f',
        disabled_subtle: '#1F242F',
        transparent: 'transparent',
        border_disabled: '#333741'
      },
      error: {
        primary: '#F97066',
        light: '#F04438',
        main: '#D92D20',
        dark: '#B42318',
      },
      warning: {
        light: '##F79009',
        main: orange[500],
        dark: '#DC6803',
      },
      success: {
        light: green[900],
        main: green[500],
        dark: green[800],
      },
      button: {
        primary_bg: '#7F6944',
        primary_bg_hover: '#635235',

        secondaryColor_bg_hover: '#1f242f',

        secondary_bg: '#161b26',
        secondary_bg_hover: '#1f242f',

        primary_fg: '#FFFFFF',

        secondary_fg: '#CECFD2',
        secondaryGray_fg: '#CECFD2',
        secondaryGray_fg_hover: '#f0f1f1',

        tertiary_fg: '#cecfd2',
        tertiary_fg_hover: '#f0f1f1',

        tertiaryGray_fg: '#94969C',
        tertiaryGray_fg_hover: '#CECFD2',

        brandPrimary_fg: '#8C734B',

        tertiaryGray_bg: '#1f242f',
        tertiaryGray_bg_hover: '#1f242f',
        
        secondary_border: '#333741',

        secondaryGray_border: '#333741',
        secondaryGray_bg_hover: '#1f242f',

        secondaryColor_border: '#333741',
        secondaryColor_hover: '#1f242f',

        secondaryColor_fg: '#cecfd2',
        secondaryColor_fg_hover: '#f0f1f1',

        primary_border: '#7F6944',
        primary_border_hover: '#635235',

        tertiaryColor_fg: '#cecfd2',
        tertiaryColor_fg_hover: '#f0f1f1',
        tertiaryColor_bg_hover: '#1f242f',

        lightGray_fg: '#CECFD2',
        lightGray_bg: '#333741',
        lightGray_bg_hover: '#333741',
        lightGray_border: '#333741',
        lightGray_border_hover: '#333741',
        bg_hover: '#1f242f',
      },
      breadcrumbs: {
        iconFG: '#94969c',
        brand_fg_hover: '#ffffff',
        brand_bg_hover: '#1f242f',
        text_fg: '#cecfd2',
        bg_hover: '#1f242f',
        fg_hover: '#ffffff',
      },
      sidenavBackgroundColor: '#0C111D',
    },
    ...generalThemeSettings,
  },
};

export default themesConfig;
