
import { HttpServices } from '../../Helper';
import { config } from '../../config';

export const GetAllGeneralPropertyCriteriaScores = async () => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyRating/GetAllGeneralPropertyCriteriaScores`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
  };
  
  export const UpdateGeneralPropertyCriteriaScores = async (body) => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/PropertyRating/UpdateGeneralPropertyCriteriaScores`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};


export const DeleteGeneralPropertyCriteria = async (generalPropertyCriteriaId ) => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/PropertyRating/DeleteGeneralPropertyCriteria/${generalPropertyCriteriaId }`, 
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};