import React, { Children } from 'react';
import { DialogComponent } from '../../../../../Components';
import LabeledField from '../../../InquiryRotationView/InquirySchemeManagement/components/LabeledField';
import { useTranslation } from 'react-i18next';

const details = [
  {
    label: 'call-has-consent',
    input: 'callHasConsent',
  },
  {
    label: 'is-approved-consent',
    input: 'isApprovedConsent',
  },
  {
    label: 'sentiment-analysis',
    input: 'sentimentAnalysis',
  },
  {
    label: 'sentiment-evaluation',
    input: 'sentimentEvaluation',
  },
  {
    label: 'advice-for-agent',
    input: 'adviceForAgent',
  },
  {
    label: 'call-assessment',
    input: 'callAssessment',
  },
  {
    label: 'agent-performance',
    input: 'agentPerformance',
  },
  {
    label: 'lead-as-opportunity-percentage',
    input: 'leadAsOpportunityPer',
  },
  {
    label: 'lead-assignment',
    input: 'leadAssignment',
  },
  {
    label: 'additional-notes',
    input: 'additionalNotes',
  },
];

function MoreDetails({ call, parentTranslationPath, open, onClose }) {
  const { t } = useTranslation(parentTranslationPath);

  const InputRender = ({ value }) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    } else {
      return value;
    }
  };
  return (
    <DialogComponent
      titleText={'call-analysis'}
      parentTranslationPath={parentTranslationPath}
      isOpen={open}
      onCloseClicked={onClose}
      dialogContent={
        <div>
          {Children.toArray(
            details.map(
              (detail) =>
                call?.[detail.input] !== null && (
                  <LabeledField
                    label={<strong>{t(detail.label)}</strong>}
                    component={<InputRender value={call?.[detail.input]} />}
                  />
                )
            )
          )}
        </div>
      }
    />
  );
}

export default MoreDetails;
