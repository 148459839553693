import React, { useState, useCallback, useEffect, useRef, useReducer } from "react";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { LeadsReassignDialog } from "../LeadsSalesView/LeadsSalesUtilities/Dialogs/LeadsReassignDialog/LeadsReassignDialog";
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  GlobalHistory,
  bottomBoxComponentUpdate,
  WhatsAppMessage,
  showError,
  showSuccess,
  GlobalTranslate,
  getIsAllowedPermission,
  getSideMenuStatus,
  havePermissionToEditInformation,
  returnPropsByPermissions,
  havePermissionToViewDetails,
  getSideMenuComponent,
  CreateActivitiesWithContactPreference,
  showWarn,
  CheckIsCommunicateAllowed,
  CheckIsWithinWorkingHours,
} from "../../../Helper";
import {
  ActionsButtonsComponent,
  ViewTypes,
  AutocompleteComponent,
  SelectComponet,
  Spinner,
  Inputs,
} from "../../../Components";
import {
  ActionsEnum,
  ViewTypesEnum,
  ActionsButtonsEnum,
  TableActions,
  FormsIdsEnum,
  TableFilterOperatorsEnum,
  MediaEnum,
  LeadTab,
  Status,
  ContactTypeEnum,
  LeadsTypesEnum,
  LeadsClassTypesEnum,
  DateFilterTypesEnum,
  SearchCriteriaEnum,
} from "../../../Enums";
import { DateRangePickerComponent } from "../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import { ActivitiesManagementDialog } from "../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog";
import { UnqualifiedLeadsDialog } from "../../DFMSharedComponents/Unqualified/UnqualifiedLeadsDialog";
import { CardDetailsComponent, LeadsCardsComponent } from "./LeadsUtilities";
import {
  GetAllSearchableFormFieldsByFormId,
  GetManagedLeadsAdvanceSearch,
  OrganizationUserSearch,
  lookupItemsGetId,
  CloseListOfLeads,
  GetAllContactLeadsAdvanceSearch,
  ReassignLeads,
  CloneLeads,
  LeadsAdvanceSearchTest,
  GetAllFormFieldsByFormId,
  GetAllMatchingUnitsByLeadId,
  leadDetailsGet,
  CheckContactCommunication,
} from "../../../Services";
import { AdvanceSearch } from "../../../SharedComponents/AdvanceSearch/AdvanceSearch";
import { AdvanceSearchSecondVersion } from "../../../SharedComponents/AdvanceSearchSecondVersion";
import { LeadsLeaseTableHeaderData } from "../LeadsLeaseView/LeadsLeaseUtilities";
import { PaginationComponent } from "../../../Components/PaginationComponent/PaginationComponent";
import { LeadsImportDialog } from "./LeadsUtilities/Dialogs/LeadsImportDialog/LeadsImportDialog";
import { ActiveItemActions } from "../../../store/ActiveItem/ActiveItemActions";
import { useTitle } from "../../../Hooks";
import { GlobalOrderFilterActions } from "../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { GlobalAdvanceSearchActions } from "../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions";
import { LeadsMapper } from "./LeadsUtilities/LeadsMapper/LeadsMapper";
import { PermissionsComponent } from "../../../Components/PermissionsComponent/PermissionsComponent";
import { LeadsCAllCenterPermissions } from "../../../Permissions/CallCenter/LeadsCallCenterPermissions";
import { TableFilterTypesEnum } from "../../../Enums/TableFilterTypes.Enum";
import { LeadsLeaseTableComponent } from "../LeadsLeaseView";
import { LeadsPermissions } from "../../../Permissions";
import { LeadsActionDialogsComponent } from "./LeadsUtilities/LeadsActionDialogsComponent/LeadsActionDialogsComponent";
import { StaticLookupsIds } from "../../../assets/json/StaticLookupsIds";
import { CloseLeadsDialog } from "./LeadsUtilities/Dialogs/CloseLeadsDialog/CloseLeadsDialog";
import { QualifyLeadDialog } from "../LeadsView/LeadsUtilities/Dialogs/QualifyLeadDialog/QualifyLeadDialog";
import { CloneLeadsDialog } from "./LeadsUtilities/Dialogs/CloneLeadsDialog/CloneLeadsDialog";
import { LeadsVerticalTabsData } from "../../Home/Common/OpenFileView/OpenFileUtilities/OpenFileData/LeadsVerticalTabsData";
import { SendToRoationDialog } from "../LeadsView/LeadsUtilities/Dialogs/SendToRoationDialog/SendToRoationDialog";
import { QualificationFilter } from "../../DFMSharedComponents/QualificationFilter/QualificationFilter.View";
import { useScrollToElement } from "../../../Hooks/useScrollToElement";
import { SendToLeadsPool } from "./LeadsUtilities/Dialogs/SendToLeadsPool/SendToLeadsPool";
import { FavoriteDFM } from "../../DFMSharedComponents/FavoriteDFM/FavoriteDFM.View";
import { RemoveLeadsOrUnitsFromFolder } from "../../../Services/FavoriteFoldersServices";
import { ConsentActionsDialog, WorkingHoursConfirmDialog } from "../../../SharedComponents";

const parentTranslationPath = "LeadsView";
const translationPath = "";

export const LeadsView = () => {
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const advanceSearch = useSelector(
    (state) => state.GlobalAdvanceSearchReducer
  );
  const [leadsTableFilter, setLeadsTableFilter] = useState(
    (advanceSearch && advanceSearch["LeadsFilter"]) || null
  );
  const [folderIdValue, setfolderIdValue] = useState(false);
  const pathName = window.location.pathname
    .split("/home/")[1]
    .split("/view")[0];
  const [list, setList] = useState([
    {
      enum: TableActions.openFile.key,
    },
    {
      enum: TableActions.editText.key,
    },
    {
      enum: TableActions.addActivity.key,
    },
  ]);
  const [selectFieldsOnAdvanceSearch, setSelectFieldsOnAdvanceSearch] =
    useState([]);
  const [filterValuesFromAdvanceSearch, setFilterValuesFromAdvanceSearch] =
    useState(null);
    const reducer = useCallback((itemsState, action) => {
      if (action.id !== 'edit') return { ...itemsState, [action.id]: action.value };
      return {
        ...action.value,
      };
    }, []);
  const [addActivity, setAddActivity] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isAllFormFieldsLoading, setIsAllFormFieldsLoading] = useState(false);
  const [displyOpenFileButton, setDisplyOpenFileButton] = useState(false);
  const [displyEditButton, setDisplyEditButton] = useState(false);
  const [displyMatchButton, setDisplyMatchButton] = useState(false);
  const [displyCloseLead, setDisplyCloseLead] = useState(false);
  const [QualificationFilterValue, setQualificationFilterValue] =
    useState(null);
  const [isOpenCloseLeadsDialog, setIsOpenCloseLeadsDialog] = useState(false);
  const [isOpenCloneLeadsDialog, setIsOpenCloneLeadsDialog] = useState(false);
  const [allFormFields, setAllFormFields] = useState([]);
  const [isCloseAction, setIsCloseAction] = useState(false);
  const [isOpenFavouriteDialog, setisOpenFavouritDialog] =useState(false);

  const [UnqualifiedLeadsDialogDialog, setUnqualifiedLeadsDialogDialog] =
    useState(false);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isAdvanceSearchCleared, setIsAdvanceSearchCleared] = useState(false);
  const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
  const [isOpenSendToRotationDialog, setIsOpenSendToRotationDialog] =
    useState(false);
  const [isOpenQualifyLeadDialog, setIsOpenQualifyLeadDialog] = useState(false);
  const [defaultValueisQualification, setdefaultValueisQualification] =
    useState(null);
  const [referred, setReferred] = useState({
    by: (orderFilter && orderFilter.ReferredByLeadTypeFilter) || null,
    to: (orderFilter && orderFilter.ReferredToLeadTypeFilter) || null,
    mediaDetails:
      (orderFilter && orderFilter.MediaDetailsLeadTypeFilter) || null,
  });
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
    selectedDateType: 1,
  };
  const [dateFilter, setDateFilter] = useState(
    (orderFilter &&
      orderFilter.LeadFilterDate &&
      orderFilter.LeadFilterDate.startDate &&
      orderFilter.LeadFilterDate.endDat !== null && {
        startDate: new Date(
          (orderFilter &&
            orderFilter.LeadFilterDate &&
            orderFilter.LeadFilterDate.startDate) ||
            null
        ),
        endDate: new Date(
          (orderFilter &&
            orderFilter.LeadFilterDate &&
            orderFilter.LeadFilterDate.endDate) ||
            null
        ),
        key: new Date(
          (orderFilter &&
            orderFilter.LeadFilterDate &&
            orderFilter.LeadFilterDate.key) ||
            null
        ),
        selectedDateType:
          (orderFilter &&
            orderFilter.LeadFilterDate &&
            orderFilter.LeadFilterDate.selectedDateType) ||
          1,
      }) ||
      dateRangeDefault
  );
  const [state, setState] = useReducer(reducer, {
    fromScore: null,
    toScore: null
  });

  const [allReferred, setAllReferred] = useState([]);
  const [allMediaName, setAllMediaName] = useState([]);
  const isPropertyManagementView = pathName === "Leads-property-management";
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState({
    allLeads: false,
    referred: false,
    mediaDetails: false,
    allFormFields: false,
  });

  // eslint-disable-next-line no-unused-vars
  const [isLoadingFormFilter, setIsLoadingFormFilter] = useState(false);
  const [isLoadingReassign, setIsLoadingReassign] = useState(false);
  const [isSearchAvite, setisSearchAvite] = useState(false);
  const [activeSelectedAction, setActiveSelectedAction] = useState(
    ActionsButtonsEnum[1].id
  );
  const [isOpenContactsActionDialog, setisOpenContactsActionDialog] =
    useState(false);
  const [detailedCardAction, setdetailedCardAction] = useState(() => ({
    actionEnum: "",
    item: "",
  }));
  const [checkedCards, setCheckedCards] = useState([]);
  const [sendToLeadsPoolDialog, setSendToLeadsPoolDialog] = useState(false);
  const [checkedCardsIds, setCheckedCardsIds] = useState([]);
  const [isOpenleadsReassignDialog, setIsOpenleadsReassignDialog] =
    useState(false);
  const [isFirst, setFirst] = useState(false);
  const searchTimer = useRef(null);
  const [searchedItem, setSearchedItem] = useState("");
  const [isFirst1, setFirst1] = useState(false);
  const [isForOwnerLeads, setIsForOwnerLeads] = useState(false);
  const [isConsentActionDialogOpen, setIsConsentActionDialogOpen] = useState(false);
  const [isWorkingHoursDialogOpen, setIsWorkingHoursDialogOpen] = useState(false);
  const loginResponse = useSelector((state) => state.login.loginResponse);


  const contactActionsHandler = (actionEnum, item) => {    

    if (actionEnum === "whatsapp" || actionEnum === "whatsappSolid") {
      const el = document.createElement("a");
      if ( item?.whatsAppMobile || item?.contact_name?.mobile) {
        el.href = WhatsAppMessage(
          item.whatsAppMobile || item.contact_name.mobile
        );
        el.target = "blank";
        el.click();
        CreateActivitiesWithContactPreference(
          actionEnum,
          item.id,
          loginResponse
        );
      } else showError(t(`${translationPath}Failure-Open-WhatsApp`));
    } else {
      setisOpenContactsActionDialog(true);
      CreateActivitiesWithContactPreference(
        actionEnum,
        item?.id,
        loginResponse
      );
    }
  }

  const checkIsActionAllowed = async (leadId) => {
    const isWithinWorkingHours = await CheckIsWithinWorkingHours({ leadId });
    const isCommunicateAllowed = await CheckIsCommunicateAllowed({ leadId });
    return (isWithinWorkingHours && isCommunicateAllowed) || false;
  };

  const unAllowedActionHandler = async (leadId) => {
    const isWithinWorkingHours = await CheckIsWithinWorkingHours({ leadId });
    const isCommunicateAllowed = await CheckIsCommunicateAllowed({ leadId });

    if (!isWithinWorkingHours) {
      setIsWorkingHoursDialogOpen(true);
      return;
    }
    if (!isCommunicateAllowed) {
      setIsConsentActionDialogOpen(true);
      return;
    }
  };
  const detailedCardActionClicked = useCallback(
    (actionEnum, item) => async (event) => {
      event.stopPropagation();

      const isActionAllowed = await checkIsActionAllowed(item?.id); 
      setdetailedCardAction({
        actionEnum,
        item,
      });
      if(isActionAllowed) contactActionsHandler(actionEnum, item);
      else  unAllowedActionHandler(item?.id);
      // eslint-disable-next-line no-console
    },
    [loginResponse]
  );
  const [activeActionType, setActiveActionType] = useState(
    (localStorage.getItem("ViewType") &&
      JSON.parse(localStorage.getItem("ViewType")).leadsView) ||
      ViewTypesEnum.cards.key
  );

  const [isExpanded, setIsExpanded] = useState(
    activeActionType === ViewTypesEnum.cardsExpanded.key
  );
  const [orderByToggler, setOrderByToggler] = useState(false);
  const [leadType, setLeadType] = useState(
    orderFilter.ActiveLeadTypeFilter || 0
  );
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: orderFilter?.unitsSalesFilter?.filterBy,
    orderBy: orderFilter?.unitsSalesFilter?.orderBy,
  });
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: "createdOn", orderBy: 2 }
  );
  const [detailsLeadsList, setDetailsLeadsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));
  const { setHash } = useScrollToElement(!!detailsLeadsList.result.length);
  // this one to change searchable form fields
  const [filterFormType, setFilterFormType] = useState(
    FormsIdsEnum.leadsOwner.id
  );
  // this one to change get leads by type
  const [activeFormType, setActiveFormType] = useState(
    orderFilter.StatusActiveFormTypeLeadTypeFilter || 0
  );
  const [searchableFormFields, setSearchableFormFields] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [contactsFilterSearchDto, setContactsFilterSearchDto] = useState(null);
  const [status, setStatus] = useState(
    orderFilter.StatusTypeLeadTypeFilter || 0
  );
  const [searchInputValue, setSearchInputValue] = useState("");
  const [leadsFiltersCriteria, setLeadsFiltersCriteria] = useState(null);
  const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);

  // Start New Code states
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex:
      JSON.parse(localStorage.getItem("contactLeadsCurrentPageIndex")) || 0,
    search: "",
  });
  // End New Code
  useTitle(t(`${translationPath}leads`));

  const onTypeChanged = useCallback(
    (activeType) => {
      let ViewTypeData = JSON.parse(localStorage.getItem("ViewType"));
      if (ViewTypeData) {
        ViewTypeData.leadsView = activeType;
        localStorage.setItem("ViewType", JSON.stringify(ViewTypeData));
      } else
        localStorage.setItem(
          "ViewType",
          JSON.stringify({ leadsView: activeType })
        );

      setActiveActionType(activeType);
      setIsExpanded(activeType === ViewTypesEnum.cardsExpanded.key);
      sideMenuIsOpenUpdate(false);
      sideMenuComponentUpdate(null);
    },
    [setActiveActionType]
  );

  const onFilterValuesChanged = (newValue) => {
    setLeadsTableFilter(newValue);
  };
  const onPageIndexChanged = (pageIndex) => {
    localStorage.setItem(
      "contactLeadsCurrentPageIndex",
      JSON.stringify(pageIndex)
    );
    setFilter((item) => ({ ...item, pageIndex }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };

  const restorePageIndex = useCallback(() => {
    const currentPageIndex = localStorage.getItem(
      "contactLeadsCurrentPageIndex"
    );

    if (currentPageIndex) {
      const pageIndex = JSON.parse(currentPageIndex);
      setFilter((item) => ({ ...item, pageIndex }));
    }
  }, []);

  useEffect(() => {
    restorePageIndex();
  }, []);

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };

  const filterOnChange = (event, newValue) => {
    const emptyKeyIndex = newValue.findIndex((item) => !item.value);
    if (!searchInputValue && emptyKeyIndex !== -1) {
      newValue.splice(emptyKeyIndex, 1);
      return;
    }
    if (emptyKeyIndex !== -1) newValue[emptyKeyIndex].value = searchInputValue;
    if (
      filterSearchDto &&
      Object.keys(filterSearchDto).length > 0 &&
      newValue.length === 0
    ) {
      onPageIndexChanged(0);
      setFilterSearchDto(null);
    }
    setSearchData([...newValue]);
  };
  const changeActiveFormType = (value) => {
    setActiveFormType(value);
    setLeadType(0);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        StatusActiveFormTypeLeadTypeFilter: value,
      })
    );
  };
  const changeActiveLeadType = (value) => {
    setLeadType(value);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ActiveLeadTypeFilter: value,
      })
    );
  };
  const changeStatusType = (value) => {
    setStatus(value);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        StatusTypeLeadTypeFilter: value,
      })
    );
  };

  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    Promise.all([
      await GetAllFormFieldsByFormId(FormsIdsEnum.leadsOwner.id),
      await GetAllFormFieldsByFormId(FormsIdsEnum.leadsSeeker.id),
    ])
      .then((result) => {
        if (Array.isArray(result[0]) && Array.isArray(result[1])) {
          const concantinateFields = result[0]
            .concat(result[1])
            .filter(
              (field, index, array) =>
                array.findIndex(
                  (element) => element.formFieldKey === field.formFieldKey
                ) === index
            );
          const list = concantinateFields
            .filter(
              (e) =>
                e.formFieldName !== "fitting_and_fixtures" &&
                e.formFieldName !== "view" &&
                e.formFieldName !== "developer" &&
                e.formFieldName !== "bathrooms" &&
                e.formFieldName !== "bedrooms" &&
                e.formFieldName !== "size_sqft" &&
                e.formFieldName !== "budget" &&
                e.formFieldName !== "propertyunit_type"
            )
            .map((field) => ({
              id: field.formFieldId || null,
              key: field.formFieldKey || null,
              isDate: field.uiWidgetType === "alt-date" || false,
              label:
                (field.formFieldTitle === "Lead Auditor"
                  ? (field.formFieldTitle = "Lead Owner")
                  : field.formFieldTitle &&
                    field.formFieldTitle.replace("*", "")) || "",
              input: field.displayPath || "",
              isNumber:
                (field.propertyJson &&
                  JSON.parse(field.propertyJson).schema &&
                  field.propertyJson &&
                  JSON.parse(field.propertyJson).schema.specialKey ===
                    "currency") ||
                (field.propertyJson &&
                  JSON.parse(field.propertyJson).schema &&
                  field.propertyJson &&
                  JSON.parse(field.propertyJson).schema.specialKey ===
                    "decimal") ||
                (field.propertyJson &&
                  JSON.parse(field.propertyJson).schema &&
                  field.propertyJson &&
                  JSON.parse(field.propertyJson).schema.specialKey === "size"),
              isSortable: true,
              searchableKey: field.searchableKey,
              data: JSON.parse(field.propertyJson).schema,
              fieldType:
                (field.propertyJson &&
                  JSON.parse(field.propertyJson).schema.uiType) ||
                (field.propertyJson &&
                  JSON.parse(field.propertyJson).uiSchema["ui:widget"]) ||
                (field.uiWidgetType === null && "text"),
            }));
          setAllFormFields(list);
        } else setAllFormFields([]);
        setIsAllFormFieldsLoading(false);
      })
      .catch(() => {
        setAllFormFields([]);
        setIsAllFormFieldsLoading(false);
      });
  }, []);


  
  const handleRemoveFavorite = async (lead) => {
    const type = 'LEAD';
    const ids = lead && lead.id;
    const index = searchData.findIndex((item) => item.key === 'FavoriteFolderId');
    const folderId = index !== -1 ? searchData[index].value : folderIdValue;
  
    const result = await RemoveLeadsOrUnitsFromFolder(type, [ids], folderId);
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t('Shared:item-remove-from-folder'));
     // setIsFavorite(false);
      reloadData();
    } else {
      console.error(`Failed to remove ${type.toLowerCase()}s`);
    }
  };


  const GetSideMenuStatus = useCallback(() => {
    let isMenuOpen = getSideMenuStatus();
    let activeComponentOpen =
      getSideMenuComponent() && getSideMenuComponent().type;

    if (!isMenuOpen) setAdvanceSearchBtn(true);
    else if (activeComponentOpen === AdvanceSearch) setAdvanceSearchBtn(false);
  });

  const searchAdvanceSearch = (newFilter) => {
    const searchObj = newFilter ? { ...newFilter } : null;
    if (!(newFilter && newFilter.length))
      localStorage.removeItem("LeadsFilter");

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        LeadsFilter: searchObj || null,
      })
    );
    setLeadsTableFilter(searchObj);
  };

  const onAdvanceSearchClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (advanceSearchBtn) {
        sideMenuIsOpenUpdate(true);
        setAdvanceSearchBtn(false);
      } else {
        sideMenuIsOpenUpdate(false);
        setAdvanceSearchBtn(true);
      }

      sideMenuComponentUpdate(
        <AdvanceSearchSecondVersion
          filterData={[...LeadsLeaseTableHeaderData, ...allFormFields]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onFilterValuesChanged={onFilterValuesChanged}
          localStorageKey="LeadsFilter"
          isAdvanceSearchCleared={isAdvanceSearchCleared}
          setIsAdvanceSearchCleared={setIsAdvanceSearchCleared}
          setOrderBy={setOrderBy}
          searchCriteriaTypeId={SearchCriteriaEnum.Lead.typeId}
          setIsAdvanceSearchActive={setIsAdvanceSearchActive}
          fields={[...LeadsLeaseTableHeaderData, ...allFormFields]}
          selectFieldsOnAdvanceSearch={selectFieldsOnAdvanceSearch}
          setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
          setFilterValuesFromAdvanceSearch={setFilterValuesFromAdvanceSearch}
          filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
          searchAdvanceSearch={searchAdvanceSearch}
        />
      );
    },
    [
      isAllFormFieldsLoading,
      allFormFields,
      advanceSearchBtn,
      isAdvanceSearchCleared,
      selectFieldsOnAdvanceSearch,
    ]
  );

  const onClearedAllFiltersClick = useCallback(() => {
    setFilterSearchDto(null);
    localStorage.removeItem("LeadQualificationId");
    setQualificationFilterValue(null);
    setdefaultValueisQualification(null);
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: "createdOn", orderBy: 2 });
    setActiveFormType(0);
    setSearchData([]);
    setDateFilter(dateRangeDefault);
    setReferred((item) => ({
      ...item,
      by: null,
      to: null,
      mediaDetails: null,
    }));
    setLeadType(0);
    setStatus(0);
    setIsClearFiltersClicked(true);
    setIsAdvanceSearchCleared(true);
    setLeadsTableFilter(null);
    setFilterSearchDto(null);
    sideMenuIsOpenUpdate(false);
    setState({ id: 'fromScore', value: null });
    setState({ id: 'toScore', value: null });
    setIsAdvanceSearchActive(false);
    setActiveSelectedAction("add");
    setFilterValuesFromAdvanceSearch();
    setSelectFieldsOnAdvanceSearch([]);

    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ReferredByLeadTypeFilter: {
          id: "",
          fullName: "",
        },

        ReferredToLeadTypeFilter: {
          id: "",
          fullName: "",
        },
        MediaDetailsLeadTypeFilter: {
          lookupItemId: "",
          lookupItemName: "",
        },
        leadsFilter: {
          filterBy: null,
          orderBy: null,
        },
        ActiveLeadTypeFilter: 0,
        StatusTypeLeadTypeFilter: 0,
        StatusActiveFormTypeLeadTypeFilter: 0,
      })
    );

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        LeadsFilter: null,
      })
    );
  }, []);

  const getAllSearchableFormFieldsByFormId = useCallback(async () => {
    setIsLoadingFormFilter(true);
    const result = await GetAllSearchableFormFieldsByFormId(filterFormType);
    if (!(result && result.status && result.status !== 200)) {
      const list = [];
      list.push({ key: "Ids", title: "Lead Id" });
      list.push({ key: "isFavorite", title: "Is Favorite" });
      result
        .filter((item) => item.isSearchable)
        .map((item) =>
          list.push({
            key: item.searchableKey,
            title: item.formFieldTitle,
          })
        );
      setSearchableFormFields(list);
    } else setSearchableFormFields([]);
    setIsLoadingFormFilter(false);
  }, [filterFormType]);

  const checkIfSensitiveField = (contactPreferenceType, item, contact_type) => {
    if (+contact_type === 1) {
      if (
        contactPreferenceType === "Email" &&
        item &&
        item.name &&
        item.name === "********"
      )
        return false;
      else if (
        contactPreferenceType === "SMS" &&
        item &&
        item.name &&
        item.name === "********"
      )
        return false;
      else if (
        contactPreferenceType === "WhatsApp" &&
        item &&
        item.name &&
        item.name === "********"
      )
        return false;
      else if (
        contactPreferenceType === "Call" &&
        item &&
        item.name &&
        item.name === "********"
      )
        return false;
    } else if (+contact_type === 2) {
      if (
        contactPreferenceType === "Email" &&
        item &&
        item.name &&
        item.name === "********"
      )
        return false;
      else if (
        contactPreferenceType === "SMS" &&
        item &&
        item.name &&
        item.name === "********"
      )
        return false;
      else if (
        contactPreferenceType === "WhatsApp" &&
        item &&
        item.name &&
        item.name === "********"
      )
        return false;
      else if (
        contactPreferenceType === "Call" &&
        item &&
        item.name &&
        item.name === "********"
      )
        return false;
    }
    return true;
  };

  const getLeadsData = useCallback(
    async (f) => {
      const isViewAllData = pathName.includes("Leads-property-management")
        ? returnPropsByPermissions(
            LeadsPermissions.ViewandsearchinPropertyManagementLeads
              .permissionsId
          )
        : returnPropsByPermissions(
            LeadsCAllCenterPermissions.ViewAndSearchInCallCenterLeads
              .permissionsId
          );
      if (isViewAllData) {
        setIsLoading((item) => ({ ...item, allLeads: true }));
        const localFilterDto = { ...f, ...filterSearchDto } || {};

        const filterLeadReferredToRole = loginResponse.roles.find(
          (role) => role.rolesName === "Filter Lead Referred To"
        );
        if (filterLeadReferredToRole) {
          localFilterDto["leadReferredToId"] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: loginResponse.userId,
            },
          ];
        }

        if (activeFormType) {
          localFilterDto.lead_type_id = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: activeFormType,
            },
          ];
        }
        if (leadType) {
          localFilterDto.leadClass = [
            {
              searchType: TableFilterOperatorsEnum.contains.key,
              value: leadType,
            },
          ];
        }
        if (status) {
          localFilterDto["status.lookupItemName"] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: (status || "").toLowerCase(),
            },
          ];
        }
        if (state.toScore) {
          localFilterDto["toScore"] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: state.toScore,
            },
          ];
        }
        if (state.fromScore) {
          localFilterDto["fromScore"] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: state.fromScore,
            },
          ];
        }
        if (referred.by && referred.by.id) {
          localFilterDto["referredbyid"] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: referred.by.id,
            },
          ];
        }
        if (referred.to && referred.to.id) {
          localFilterDto["referredtoid"] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: referred.to.id,
            },
          ];
        }
        if (QualificationFilterValue && QualificationFilterValue !== null) {
          localFilterDto.LeadQualificationId = [QualificationFilterValue];
        }
        if (referred.mediaDetails && referred.mediaDetails.lookupItemName) {
          localFilterDto["media_detail.lookupItemName"] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: (referred.mediaDetails.lookupItemName || "").toLowerCase(),
            },
          ];
        }
        if (leadsTableFilter) {
          Object.values(leadsTableFilter)
            .filter((item) => item.searchableKey || item.displayPath)
            .map((item) => {
              if (localFilterDto[item.searchableKey || item.displayPath]) {
                localFilterDto[item.searchableKey || item.displayPath].push({
                  searchType: item.operator,
                  value: item.value,
                });
              } else if (item.value) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: item.value,
                  },
                ];
              } else if (
                !item.value &&
                (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
                  item.operator === TableFilterOperatorsEnum.isBlank.key)
              ) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: null,
                  },
                ];
              }
              return undefined;
            });
        }
        setLeadsFiltersCriteria(localFilterDto);

        if (Object.values(localFilterDto).length)
          setIsAdvanceSearchActive(true);
        else setIsAdvanceSearchActive(false);

        const body = {
          criteria: localFilterDto,
          ...orderBy,
        };
        setIsDatePickerChanged(false);
        if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
          const fromDate = moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss");
          const toDate = moment(dateFilter.endDate).format(
            "YYYY-MM-DDTHH:mm:ss"
          );

          if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.CreatedOnDate.key
          ) {
            body.fromDate = fromDate;
            body.toDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.UpdatedOnDate.key
          ) {
            body.updatedFromDate = fromDate;
            body.updatedToDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.LastAssignDate.key
          ) {
            body.assignedFromDate = fromDate;
            body.assignedToDate = toDate;
          }
        }
        if (
          leadsTableFilter &&
          leadsTableFilter.lastActivtyTypeName &&
          leadsTableFilter.lastActivtyTypeName.value !== null &&
          leadsTableFilter.lastActivtyTypeName.value !== ""
        )
          body.lastActivtyTypeName = leadsTableFilter.lastActivtyTypeName.value;

        if (
          leadsTableFilter &&
          leadsTableFilter.lastActivityCreatedByName &&
          leadsTableFilter.lastActivityCreatedByName.value !== null &&
          leadsTableFilter.lastActivityCreatedByName.value !== ""
        )
          body.lastActivityCreatedByName =
            leadsTableFilter.lastActivityCreatedByName.value;

        if (!pathName.includes("Leads-property-management")) {
          const res = await LeadsAdvanceSearchTest(filter, body);

          if (!(res && res.status && res.status !== 200)) {
            setDetailsLeadsList({
              result: ((res && res.result) || []).map((item) => {
                const lead = item.leadJson && JSON.parse(item.leadJson).lead;

                const leadTypeIndex = Object.values(
                  LeadsClassTypesEnum
                ).findIndex((element) => element.key === item.leadClass);
                const leadClass =
                  (leadTypeIndex !== -1 &&
                    Object.values(LeadsClassTypesEnum)[leadTypeIndex].name) ||
                  null;
                  item.country = lead?.country;
                  item.city = lead?.city;
                  item.district = lead?.district;
                  item.community = lead?.community;
                  item.subCommunity = lead?.sub_community;
                if (lead) {
                  return {
                    ...lead,
                    leadClass,
                    id: item.leadId,
                    bulkUpload: item.bulkUpload,
                    leadTypeId: lead.lead_type_id,
                    imagePath: null,
                    createdBy: item.createdBy,
                    isFavorite: item.isFavorite,
                    updatedBy: item.updatedBy,
                    lastActivtyTypeName: item.lastActivtyTypeName,
                    lastActivityComment: item.lastActivityComment,
                    country: item.country,
                    city: item.city,
                    district: item.district,
                    community: item.community,
                    subCommunity: item.subCommunity,
                    budgetTo: item.budgetTo || "",
                    budgetFrom: item.budgetFrom || "",
                    sizeAreaTo: item.sizeAreaTo,
                    sizeAreaFrom: item.sizeAreaFrom,
                    unitType: item.leadUnitType,
                    imagePath: null,
                    sale_type: lead.sale_type,
                    lastActivityCreatedByName: item.lastActivityCreatedByName,
                    assignDate:
                      (moment(item.assignDate).isValid() && item.assignDate) ||
                      null,
                    name: `${
                      (lead.contact_name && lead.contact_name.name) || ""
                    }`,
                    whatsAppMobile: item.whatsAppMobile,
                    matchingUnits: lead.matching_units || [],
                    matchingUnitsNumber: item.matchUnit || 0,
                    createdOn:
                      (moment(item.createdOn).isValid() && item.createdOn) ||
                      null,
                    lastActivityDate:
                      (moment(item.lastActivityDate).isValid() &&
                        item.lastActivityDate) ||
                      null,
                    updateDate: item.updateOn,
                    type: ContactTypeEnum.man.value,
                    contactPreferences:
                      item.contactPreferences &&
                      item.contactPreferences.split(","),
                    leadType:
                      (lead.lead_type_id === 1 &&
                        ((LeadsTypesEnum.Owner && LeadsTypesEnum.Owner.value) ||
                          "N/A")) ||
                      (lead.lead_type_id === 2 &&
                        ((LeadsTypesEnum.Seeker &&
                          LeadsTypesEnum.Seeker.value) ||
                          "N/A")) ||
                      "N/A",
                    rating: lead.rating || "",

                    fitting_and_fixtures:
                      lead.fitting_and_fixtures &&
                      lead.fitting_and_fixtures.length > 0
                        ? lead.fitting_and_fixtures.map(
                            (el) => `${el.lookupItemName}, `
                          )
                        : [],
                    size_sqft:
                      (lead.size_sqft && lead.size_sqft.join(" * ")) || "",

                    budget:
                      (lead.budgetTo &&
                        lead.budgetFrom &&
                        `${lead.budgetFrom} - ${lead.budgetTo}`) ||
                      "",

                    view:
                      (lead.view &&
                        lead.view.map((el) => `${el.lookupItemName}, `)) ||
                      "",
                    developer:
                      (lead.developer &&
                        lead.developer.map((el) => `${el.name}, `)) ||
                      "",

                    progress:
                      typeof lead.data_completed === "string" &&
                      lead.data_completed.includes("%")
                        ? +lead.data_completed.substr(
                            0,
                            lead.data_completed.length - 1
                          )
                        : +lead.data_completed,
                    progressWithPercentage:
                      typeof lead.data_completed !== "string"
                        ? `${lead.data_completed}%`
                        : lead.data_completed,
                    status: (lead && lead.status) || "N/A",
                    flatContent: lead.lead_type_id === 2 && [
                      {
                        iconClasses: "mdi mdi-cash-multiple",
                        title: null,
                        value: lead.budget
                          ? lead.budget.map(
                              (element, index) =>
                                `${element}${
                                  (index < lead.budget.length - 1 && ",") || ""
                                } `
                            )
                          : "N/A",
                      },
                      {
                        iconClasses: "mdi mdi-bed",
                        title: null,
                        value:
                          lead && lead.bedrooms && lead.bedrooms.length === 0
                            ? GlobalTranslate.t("Shared:any")
                            : (lead.bedrooms &&
                                lead.bedrooms.map(
                                  (element, index) =>
                                    `${element}${
                                      (index < lead.bedrooms.length - 1 &&
                                        ",") ||
                                      ""
                                    } `
                                )) ||
                              GlobalTranslate.t("Shared:any"),
                      },
                      {
                        iconClasses: "mdi mdi-shower",
                        title: null,
                        value:
                          lead && lead.bathrooms && lead.bathrooms.length === 0
                            ? GlobalTranslate.t("Shared:any")
                            : (lead.bathrooms &&
                                lead.bathrooms.map(
                                  (element, index) =>
                                    `${element}${
                                      (index < lead.bathrooms.length - 1 &&
                                        ",") ||
                                      ""
                                    } `
                                )) ||
                              GlobalTranslate.t("Shared:any"),
                      },
                      {
                        iconClasses: "mdi mdi-ruler-square",
                        title: "sqf",
                        value: lead.size_sqft
                          ? lead.size_sqft.map(
                              (element, index) =>
                                `${element}${
                                  (index < lead.size_sqft.length - 1 && ",") ||
                                  ""
                                } `
                            )
                          : "N/A",
                      },
                    ],
                    details: [
                      {
                        iconClasses: "mdi mdi-clipboard-account-outline",
                        title: "lead-type",
                        value:
                          lead.lead_type_id === 1
                            ? t(`${translationPath}owner`)
                            : t(`${translationPath}seeker`),
                      },
                      {
                        iconClasses: "mdi mdi-account-circle",
                        title: "stage",
                        value: lead.lead_stage
                          ? lead.lead_stage.lookupItemName
                          : "N/A",
                      },
                      {
                        iconClasses: "mdi mdi-account-box",
                        title: "contact-name",
                        value: lead.contact_name
                          ? lead.contact_name.name
                          : "N/A",
                      },
                      {
                        iconClasses: "mdi mdi-table-furniture",
                        title: "equipments-and-fixtures",
                        value:
                          (lead.fitting_and_fixtures &&
                            lead.fitting_and_fixtures.map(
                              (element, index) =>
                                `${element.lookupItemName}${
                                  (index <
                                    lead.fitting_and_fixtures.length - 1 &&
                                    ",") ||
                                  ""
                                } `
                            )) ||
                          "N/A",
                      },
                      {
                        iconClasses: "mdi mdi-window-open-variant",
                        title: "views",
                        value:
                          (lead.view &&
                            ((Array.isArray(lead.view) &&
                              lead.view.map(
                                (element, index) =>
                                  `${element.lookupItemName}${
                                    (index < lead.view.length - 1 && ",") || ""
                                  } `
                              )) ||
                              (typeof lead.view === "object" &&
                                lead.view.lookupItemName) ||
                              "N/A")) ||
                          "N/A",
                      },
                      {
                        iconClasses: "mdi mdi-laptop-windows",
                        title: "developers",
                        value:
                          (lead.developers &&
                            lead.developers.map(
                              (element, index) =>
                                `${element.lookupItemName}${
                                  (index < lead.developers.length - 1 && ",") ||
                                  ""
                                } `
                            )) ||
                          "N/A",
                      },
                    ],
                    psi_opportunity: item.psi_opportunity || "",
                    leadsPoolAvailability: item.leadsPoolAvailability,
                    leadsPoolSource: item.leadsPoolSource,
                    averageLeadScore:item.averageLeadScore || null,
                    assetsConsent: item.assetsConsent || '',
                    mainConsent: item.mainConsent || '',
                    dncr: item.dncr || '',
                    angryBird: item.angryBird,
                    highProfile: item.highProfile
                  };
                }
              }),
              totalCount: (res && res.totalCount) || 0,
            });
          } else {
            setDetailsLeadsList({
              result: [],
              totalCount: 0,
            });
          }
        } else {
          const res = await GetManagedLeadsAdvanceSearch(filter, body);

          if (!(res && res.status && res.status !== 200)) {
            setDetailsLeadsList({
              result: ((res && res.result) || []).map((item) =>
                LeadsMapper(item, res, t)
              ),
              totalCount: (res && res.totalCount) || 0,
            });
          } else {
            setDetailsLeadsList({
              result: [],
              totalCount: 0,
            });
          }
        }
        setIsLoading((item) => ({ ...item, allLeads: false }));
      }
    },
    [
      activeFormType,
      filter,
      filterSearchDto,
      leadType,
      status,
      leadsTableFilter,
      orderBy,
      pathName,
      t,
      referred,
      dateFilter,
      state
    ]
  );

  const getContactLeadsData = useCallback(async () => {
    const body = {
      criteria: contactsFilterSearchDto,
      ...orderBy,
    };
    const leadTab = LeadTab.CallCenter;
    setIsLoading((item) => ({ ...item, allLeads: true }));
    const res = await GetAllContactLeadsAdvanceSearch(leadTab, filter, body);

    if (!pathName.includes("Leads-property-management")) {
      if (!(res && res.status && res.status !== 200)) {
        setDetailsLeadsList({
          result: ((res && res.result) || []).map((item) =>
            LeadsMapper(item, res, t)
          ),
          totalCount: (res && res.totalCount) || 0,
        });
      } else {
        setDetailsLeadsList({
          result: [],
          totalCount: 0,
        });
      }
    } else if (!(res && res.status && res.status !== 200)) {
      setDetailsLeadsList({
        result: ((res && res.result) || []).map((item) =>
          LeadsMapper(item, res, t)
        ),
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setDetailsLeadsList({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((item) => ({ ...item, allLeads: false }));
  });

  const reassignHandler = async (reassignItem) => {
    setFilter((item) => ({ ...item, pageIndex: 0, leadStatus: null }));
    showSuccess(t(`${translationPath}leads-reassign-notification`));
    setIsOpenleadsReassignDialog(false);
    await ReassignLeads({
      leadIds: checkedCardsIds,
      referredToId: reassignItem.referredToId,
      isCopyTo: reassignItem.isCopyTo,
    });
  };
  const searchClicked = async () => {
    if (searchData.length === 0) return;
    localStorage.setItem("LeadsFilter", JSON.stringify(searchData));
    setSearchedItem("");
    setFilterSearchDto(
      searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType:
                TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
    );
    onPageIndexChanged(0);
  };


  const SearchHandlerComponent = useCallback((key, title, data, delay) => {
    const newValue = [{ key, title, value: data }];
    if (searchTimer.current) clearTimeout(searchTimer.current);

    if (data === "") {
      const index = searchData.findIndex((item) => item.key === key);
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem("UnitSaleFilter");
    } else {
      searchTimer.current = setTimeout(() => {
        setSearchData([...newValue]);
        setFilterSearchDto({ [key]: [{ value: data }] });
      }, delay);
    }
  }, [searchData]);


  
  const searchchachedClickedWithoutFilter = async (data) => {
    if (data.length === 0) return;
    const oldfilter = data.reduce((total, item) => {
      if (total[item.key]) {
        total[item.key].push({
          searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
          value: item.value,
        });
      } else {
        total[item.key] = [
          {
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          },
        ];
      }
      return total;
    }, {});
    getLeadsData(oldfilter);
  };

  const searchHandler = (data) => {
    const newV = [
      {
        key: "All",
        title: "All",
        value: data,
      },
    ];

    if (searchTimer.current) clearTimeout(searchTimer.current);
    if (data === "") {
      const index = searchData.findIndex((item) => item.key === "All");
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem("LeadsFilter");
    } else {
      searchTimer.current = setTimeout(() => {
        setSearchData([...newV]);
        setFilterSearchDto({
          All: [
            {
              value: data,
            },
          ],
        });
      }, 800);
    }
  };

  const tableActionClicked = useCallback(
    async (actionEnum, item) => {

      dispatch(ActiveItemActions.activeItemRequest(item));
      localStorage.setItem("leadStatus", JSON.stringify(item.status));
      if (actionEnum === TableActions.openFile.key) {
        GlobalHistory.push(
          `/home/leads/lead-profile-edit?formType=${item.leadTypeId}&id=${item.id}&leadClass=${item.leadClass}`
        );
      } else if (actionEnum === TableActions.editText.key)
        GlobalHistory.push(
          `/home/leads/edit?formType=${item.leadTypeId}&id=${item.id}`
        );
      else if (actionEnum === TableActions.addActivity.key) {
          setAddActivity(true);
          setActiveItem(item);
      }
    },
    [dispatch]
  );
  const displayedLabel = (option) => `${option.title}: ${searchInputValue}`;
  const disabledOptions = (option) => option.disabledOnSelect;
  const chipsLabel = (option) => `${option.title}: ${option.value}`;
  const inputValueChanged = (event, newInputValue) => {
    setSearchInputValue(newInputValue);
  };
  const onActionButtonChanged = (activeAction) => {
    setActiveSelectedAction(activeAction);

    if (activeAction === ActionsButtonsEnum[5].id) {
      changeActiveFormType(0);
      changeActiveLeadType(0);
      changeStatusType("Open");
    } else if (
      activeAction === ActionsButtonsEnum[6].id ||
      activeAction === ActionsButtonsEnum[8].id ||
      activeAction === ActionsButtonsEnum[12].id
    ) {
      changeActiveFormType(2);
      changeActiveLeadType("Buyer");
      changeStatusType("Open");
      setIsForOwnerLeads(false);
    } else if (activeAction === ActionsButtonsEnum[14].id) {
      changeActiveFormType(1);
      changeActiveLeadType("Seller");
      changeStatusType("Open");
    } else if (activeAction === ActionsButtonsEnum[7].id) {
      changeActiveFormType(2);
      changeActiveLeadType("Buyer");
      changeStatusType("Closed");
    } else if (activeAction === ActionsButtonsEnum[22].id) {
      changeStatusType("Open");
    } else if (activeAction === ActionsButtonsEnum[23].id) {
      changeActiveFormType(1);
      changeActiveLeadType("Seller");
      changeStatusType("Open");
      setIsForOwnerLeads(true);
    }
    setIsCloseAction(false);
    setCheckedCards([]);
    setCheckedCardsIds([]);
  };
  const onActionsButtonClicked = useCallback(
    (activeAction) => {
      if (activeAction === ActionsButtonsEnum[3].id)
        setIsOpenImportDialog(true);
      if (activeAction === ActionsButtonsEnum[2].id) {
        GlobalHistory.push(
          `/home/lead-sales/merge?firstId=${checkedCardsIds[0]}&secondId=${checkedCardsIds[1]}&leadTypeId=${checkedCards[0].leadTypeId}`
        );
      }
      if (activeAction === ActionsButtonsEnum[5].id)
        setIsOpenCloseLeadsDialog(true);
      if (
        activeAction === ActionsButtonsEnum[6].id ||
        activeAction === ActionsButtonsEnum[14].id
      )
        setIsOpenleadsReassignDialog(true);
      if (activeAction === ActionsButtonsEnum[7].id)
        setIsOpenCloneLeadsDialog(true);
      if (
        activeAction === ActionsButtonsEnum[8].id ||
        activeAction === ActionsButtonsEnum[23].id
      )
        setIsOpenSendToRotationDialog(true);
      if (activeAction === ActionsButtonsEnum[12].id)
        setIsOpenQualifyLeadDialog(true);
      if (activeAction === ActionsButtonsEnum[13].id)
        setUnqualifiedLeadsDialogDialog(true);
      if (activeAction === ActionsButtonsEnum[22].id)
      setSendToLeadsPoolDialog(true);
      if (activeAction === ActionsButtonsEnum[26].id) {
        setisOpenFavouritDialog(true);
      }
    },
    [checkedCards, checkedCardsIds]
  );
  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      setHash(activeData.id);
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      localStorage.setItem("leadStatus", JSON.stringify(activeData.status));

      event.stopPropagation();

      if (actionEnum === ActionsEnum.reportEdit.key) {
        GlobalHistory.push(
          `/home/leads/edit?formType=${activeData.leadTypeId}&id=${activeData.id}`
        );
      } else if (actionEnum === ActionsEnum.folder.key) {
        GlobalHistory.push(
          `/home/leads/lead-profile-edit?formType=${activeData.leadTypeId}&id=${activeData.id}&leadClass=${activeData.leadClass}`
        );
      } else if (actionEnum === ActionsEnum.matching.key) {
        setIsLoading(true);

        const result = await GetAllMatchingUnitsByLeadId(activeData.id, 1, 25);
        if (!(result && result.status && result.status !== 200)) {
          setIsLoading(false);
          if (result && result.totalCount)
            GlobalHistory.push(
              `/home/leads/lead-profile-edit?formType=${
                activeData.leadTypeId
              }&id=${activeData.id}&matching=${true}&leadClass=${
                activeData.leadClass
              }`
            );
           else {
              localStorage.setItem(
                "leadDetails",
                JSON.stringify(activeData)
              );
              GlobalHistory.push(
                activeData.leadClass && activeData.leadClass === "Buyer"
                  ? `/home/zero-matching-sale/add?formType=1&leadId=${activeData.id}`
                  : `/home/zero-matching-lease/add?formType=2&leadId=${activeData.id}`
              );
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else if (actionEnum === TableActions.addActivity.key) {
        setAddActivity(true);
      }
    },
    [dispatch]
  );
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        leadsFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setOrderByToggler(false);
  };

  const cardCheckboxClicked = useCallback(
    (itemIndex, element) => {
      setCheckedCards((items) => {
        const index = items.findIndex((item) => item.id === element.id);
        if (index !== -1) items.splice(index, 1);
        else {
          const isSelectOneOnly =
            activeSelectedAction === ActionsButtonsEnum[12].id &&
            items &&
            items.length == 1;
          if (isSelectOneOnly) return [...items];
          items.push(element);
        }
        return [...items];
      });
      setCheckedCardsIds((items) => {
        const index = items.findIndex((item) => item === element.id);
        if (index !== -1) items.splice(index, 1);
        else {
          const isSelectOneOnly =
            activeSelectedAction === ActionsButtonsEnum[12].id &&
            items &&
            items.length == 1;
          if (isSelectOneOnly) return [...items];
          items.push(element.id);
        }
        return [...items];
      });
    },
    [activeSelectedAction]
  );

  const reloadData = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
    getLeadsData();
  }, [getLeadsData]);

  const closeSideMenu = () => {
    sideMenuComponentUpdate(<></>);
    sideMenuIsOpenUpdate(false);
  };
  const onCardClick = useCallback(
    (item, selectedIndex) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveCard(item);
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (getSideMenuStatus()) setAdvanceSearchBtn(true);
      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={detailsLeadsList.result[selectedIndex]}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          loginResponse={loginResponse}
          relodedata={reloadData}
          onActionClicked={detailedCardActionClicked}
          displyOpenFileButton={displyOpenFileButton}
          displyEditButton={displyEditButton}
          displyMatchButton={displyMatchButton}
          displyCloseLead={displyCloseLead}
          checkIfSensitiveField={checkIfSensitiveField}
          parentTranslationPath={parentTranslationPath}
          closeSideMenu={closeSideMenu}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [
      detailedCardActionClicked,
      detailedCardSideActionClicked,
      detailsLeadsList.result,
      dispatch,
      loginResponse,
      reloadData,
      advanceSearchBtn,
    ]
  );
  const focusedRowChanged = useCallback(
    (rowIndex, item) => {
      if (rowIndex !== -1) {
        sideMenuComponentUpdate(
          <CardDetailsComponent
            activeData={detailsLeadsList.result[rowIndex]}
            cardDetailsActionClicked={detailedCardSideActionClicked}
            loginResponse={loginResponse}
            onActionClicked={detailedCardActionClicked}
            relodedata={reloadData}
            displyOpenFileButton={displyOpenFileButton}
            displyEditButton={displyEditButton}
            displyMatchButton={displyMatchButton}
            displyCloseLead={displyCloseLead}
            checkIfSensitiveField={checkIfSensitiveField}
            parentTranslationPath={parentTranslationPath}
            closeSideMenu={closeSideMenu}
          />
        );
        sideMenuIsOpenUpdate(true);
      } else {
        sideMenuComponentUpdate(<></>);
        sideMenuIsOpenUpdate(false);
      }
      let list1 = [];
      if (
        item &&
        item.status &&
        item.status.lookupItemId &&
        displyOpenFileButton
      ) {
        list1.push({
          enum: TableActions.openFile.key,
          isDisabled: false,
          externalComponent: null,
        });
      }
      if (
        returnPropsByPermissions(
          LeadsCAllCenterPermissions.AddNewActivity.permissionsId
        ) &&
        item &&
        item.status &&
        item.status.lookupItemId !== StaticLookupsIds.Closed
      ) {
        list1.push({
          enum: TableActions.addActivity.key,
          isDisabled: false,
          externalComponent: null,
        });
      }
      setList(list1);
    },
    [
      detailedCardActionClicked,
      detailedCardSideActionClicked,
      detailsLeadsList.result,
      loginResponse,
      reloadData,
    ]
  );
  const onFormTypeSelectChanged = (formType) => {
    localStorage.removeItem("leadStatus");
    GlobalHistory.push(`/home/leads/add?formType=${formType}`);
  };
  const getIsSelected = useCallback(
    (row) => {
      if (!row || !row.id || !checkedCardsIds) {
        return false;
      }
      return checkedCardsIds.findIndex((item) => item === row.id) !== -1;
    },
    [checkedCardsIds]
  );
  const onSelectClicked = useCallback(
    (row) => {
      const itemIndex = checkedCardsIds
        ? checkedCardsIds.findIndex((item) => item === row.id)
        : -1;
      if (itemIndex !== -1) {
        checkedCardsIds.splice(itemIndex, 1);
        setCheckedCards((items) => {
          const elementIndex = items.findIndex((item) => item.id === row.id);
          if (elementIndex !== -1) items.splice(elementIndex, 1);
          return [...items];
        });
      } else {
        const isSelectOneOnly =
          activeSelectedAction === ActionsButtonsEnum[12].id &&
          checkedCardsIds &&
          checkedCardsIds.length == 1;
        if (isSelectOneOnly) return;

        checkedCardsIds.push(row.id);
        setCheckedCards((items) => {
          items.push(row);
          return [...items];
        });
      }
      setCheckedCardsIds(checkedCardsIds);
    },
    [checkedCardsIds, activeSelectedAction]
  );

  const getAllReferred = useCallback(async (searchValue) => {
    setIsLoading((item) => ({ ...item, referred: true }));

    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: Status.Active.value,
    });
    if (!(res && res.status && res.status !== 200)) {
      setAllReferred({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllReferred({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((item) => ({ ...item, referred: false }));
  }, []);
  const getAllMediaName = useCallback(async () => {
    setIsLoading((item) => ({ ...item, mediaDetails: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: MediaEnum.MediaDetailsId.lookupTypeId,
    });
    if (!(res && res.status && res.status !== 200)) setAllMediaName(res);
    else setAllMediaName([]);
    setIsLoading((item) => ({ ...item, mediaDetails: false }));
  }, []);

  const checkBulkDesabled = (enums) => {
    if (
      enums === ActionsButtonsEnum[5].id ||
      enums === ActionsButtonsEnum[6].id ||
      enums === ActionsButtonsEnum[7].id ||
      enums === ActionsButtonsEnum[8].id ||
      enums === ActionsButtonsEnum[12].id ||
      enums === ActionsButtonsEnum[13].id ||
      enums === ActionsButtonsEnum[14].id ||
      enums === ActionsButtonsEnum[23].id ||
      enums === ActionsButtonsEnum[22].id
    )
      return !(checkedCards && checkedCards.length >= 1);

    return false;
  };

  const resetAllValues = () => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
    setCheckedCards([]);
    setCheckedCardsIds([]);
    setIsCloseAction(true);
    setActiveSelectedAction("add");
  };

  const closeLeads = useCallback(async (item) => {
    resetAllValues();
    showSuccess(t(`${translationPath}close-lead-notification`));
    await CloseListOfLeads(item);
  }, []);

  const cloneLeads = useCallback(async (items) => {
    resetAllValues();

    const result = await CloneLeads(items);
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t(`${translationPath}clone-leads-notification`));
    } else {
      showError(t(`${translationPath}leads-clone-failed`));
    }
  }, []);

  const getLeadsActionList = () => {
    if (!isPropertyManagementView)
      return [
        LeadsCAllCenterPermissions.AddNewLead.permissionsId,
        LeadsCAllCenterPermissions.ImportLead.permissionsId,
        LeadsCAllCenterPermissions.CloseLead.permissionsId,
        LeadsCAllCenterPermissions.ReassignLead.permissionsId,
        LeadsCAllCenterPermissions.CloneLead.permissionsId,
        LeadsCAllCenterPermissions.SendToRotation.permissionsId,
        LeadsCAllCenterPermissions.ReassignSellerLead.permissionsId,
      ];
    else
      return [LeadsPermissions.AddNewLeadInPropertyManagemntPage.permissionsId];
  };

  const getImportLeadsWithPermissions = () => {
    if (!isPropertyManagementView) {
      return getIsAllowedPermission(
        Object.values(LeadsCAllCenterPermissions),
        loginResponse,
        LeadsCAllCenterPermissions.ImportLead.permissionsId
      );
    } else {
      return true;
    }
  };

  const getCloseLeadsWithPermissions = () => {
    if (!isPropertyManagementView) {
      return getIsAllowedPermission(
        Object.values(LeadsCAllCenterPermissions),
        loginResponse,
        LeadsCAllCenterPermissions.CloseLead.permissionsId
      );
    } else {
      return true;
    }
  };

  const getCloneLeadsWithPermissions = () => {
    if (!isPropertyManagementView) {
      return getIsAllowedPermission(
        Object.values(LeadsCAllCenterPermissions),
        loginResponse,
        LeadsCAllCenterPermissions.CloneLead.permissionsId
      );
    } else {
      return true;
    }
  };

  const getReassignLeadsWithPermissions = () => {
    if (!isPropertyManagementView) {
      return getIsAllowedPermission(
        Object.values(LeadsCAllCenterPermissions),
        loginResponse,
        LeadsCAllCenterPermissions.ReassignLead.permissionsId
      );
    } else {
      return true;
    }
  };

  const getEnableToSendToRotationWithPermissions = () => {
    if (!isPropertyManagementView) {
      return getIsAllowedPermission(
        Object.values(LeadsCAllCenterPermissions),
        loginResponse,
        LeadsCAllCenterPermissions.SendToRotation.permissionsId
      );
    } else {
      return true;
    }
  };

  const getEnableSendToLeadPoolWithPermissions = () => {
    if (!isPropertyManagementView) {
      return getIsAllowedPermission(
        Object.values(LeadsCAllCenterPermissions),
        loginResponse,
        LeadsCAllCenterPermissions.SendToTheLeadsPool.permissionsId
      );
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (isSearchAvite) getContactLeadsData();
  }, [contactsFilterSearchDto]);

  useEffect(() => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        LeadFilterDate: dateFilter || dateRangeDefault,
      })
    );
  }, [dateFilter]);

  useEffect(() => {
    getAllReferred();
    getAllMediaName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirst && isDatePickerChanged) {
      const searchDataFilter = searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType:
                TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {});
      getLeadsData(searchDataFilter);
    }
  }, [dateFilter, isDatePickerChanged, searchData]);

  useEffect(() => {
    getAllSearchableFormFieldsByFormId();
  }, [filterFormType, getAllSearchableFormFieldsByFormId]);

  useEffect(() => {
    const isViewAllData = pathName.includes("Leads-property-management")
      ? returnPropsByPermissions(
          LeadsPermissions.ViewandsearchinPropertyManagementLeads.permissionsId
        )
      : returnPropsByPermissions(
          LeadsCAllCenterPermissions.ViewAndSearchInCallCenterLeads
            .permissionsId
        );
    if (isViewAllData) {
      const currentPageIndex = localStorage.getItem(
        "contactLeadsCurrentPageIndex"
      );
      const pageIndex = JSON.parse(currentPageIndex);
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={pageIndex || filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={detailsLeadsList.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  useEffect(() => {
    const data = localStorage.getItem("LeadsFilter");

    if (data) {
      setSearchData(JSON.parse(data));
      searchchachedClickedWithoutFilter(JSON.parse(data));
    } else getLeadsData();
  }, []);

  useEffect(() => {
    if (isFirst1) {
      if (searchData && searchData.length === 0) {
        localStorage.removeItem("LeadsFilter");
        setSearchedItem("");
      } else localStorage.setItem("LeadsFilter", JSON.stringify(searchData));
    } else setFirst1(true);
  }, [searchData]);

  useEffect(() => {
    if (!isFirst) setFirst(true);
    else {
      const data = localStorage.getItem("LeadsFilter");
      if (data) searchchachedClickedWithoutFilter(JSON.parse(data));
      else if (searchData && searchData.length === 0) getLeadsData();
    }
  }, [
    activeFormType,
    filter,
    status,
    filterSearchDto,
    leadType,
    leadsTableFilter,
    orderBy,
    pathName,
    t,
    referred,
    searchData,
    state.fromScore,
    state.toScore
  ]);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    GetSideMenuStatus();
  }, [GetSideMenuStatus]);

  useEffect(() => {
    const openFile = !isPropertyManagementView
      ? LeadsVerticalTabsData.callCenter
      : LeadsVerticalTabsData.LeadsProprtyManagement;
    const editFile = !isPropertyManagementView
      ? LeadsCAllCenterPermissions.EditLeadDetails.permissionsId
      : LeadsPermissions.EditLeadDetails.permissionsId;
    const matchLead = !isPropertyManagementView
      ? LeadsCAllCenterPermissions.ViewMatchingUnits.permissionsId
      : LeadsPermissions.ViewMatchingUnits.permissionsId;
    const closeLead = !isPropertyManagementView
      ? LeadsCAllCenterPermissions.CloseLead.permissionsId
      : "";

    setDisplyCloseLead(returnPropsByPermissions(closeLead));
    setDisplyOpenFileButton(havePermissionToViewDetails(openFile));
    setDisplyEditButton(havePermissionToEditInformation(editFile));
    setDisplyMatchButton(returnPropsByPermissions(matchLead));
  }, [isFirst]);
  const onQualificationFilterButtonClicked = (activeAction) => {
    if (activeAction === null) {
      setQualificationFilterValue(null);
      const index = searchData.findIndex(
        (item) => item.key === "LeadQualificationId"
      );
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem("LeadQualificationId");
      setQualificationFilterValue(null);
      // getLeadsData();
    } else {
      setdefaultValueisQualification({
        searchType: 1,
        value: activeAction?.item?.lookupItemId || "",
      });
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          LeadQualificationId: {
            LeadQualificationId: [
              {
                searchType: 1,
                value: activeAction?.item?.lookupItemId || "",
              },
            ],
          },
        })
      );
      setFilterSearchDto(orderFilter.LeadQualificationId);
      setQualificationFilterValue({
        searchType: 1,
        value: activeAction?.item?.lookupItemId || "",
      });
    }
  };
  return (
    <div className="view-wrapper leads leads-wrapper">
      <Spinner isActive={isLoading.allLeads} />
      <div className="d-flex-column">
        <div className="header-section">
          <div className="filter-section">
            <div className="section">
              <PermissionsComponent
                permissionsList={
                  !isPropertyManagementView
                    ? Object.values(LeadsCAllCenterPermissions)
                    : Object.values(LeadsPermissions)
                }
                permissionsId={getLeadsActionList()}
              >
                {!isPropertyManagementView && (
                  <ActionsButtonsComponent
                    isDisabled={
                      activeSelectedAction === "merge"
                        ? checkedCards.length < 2
                        : false
                    }
                    withType
                    permissionsList={
                      !isPropertyManagementView
                        ? Object.values(LeadsCAllCenterPermissions)
                        : Object.values(LeadsPermissions)
                    }
                    addPermissionsId={
                      !isPropertyManagementView
                        ? LeadsCAllCenterPermissions.AddNewLead.permissionsId
                        : LeadsPermissions.AddNewLeadInPropertyManagemntPage
                            .permissionsId
                    }
                    selectPermissionsId={getLeadsActionList()}
                    enableImport={getImportLeadsWithPermissions()}
                    enableCloseLeads={getCloseLeadsWithPermissions()}
                    enableCloneLeads={getCloneLeadsWithPermissions()}
                    enablereassignSeekerLeads={getReassignLeadsWithPermissions()}
                    enablereassignOwnerLeads={
                      !isPropertyManagementView
                        ? returnPropsByPermissions(
                            LeadsCAllCenterPermissions.ReassignSellerLead
                              .permissionsId
                          )
                        : true
                    }
                    enableToSendToRotation={getEnableToSendToRotationWithPermissions()}
                    enableSendToOwnerRotation={getEnableToSendToRotationWithPermissions()}
                    enableQualifyLead={true}
                    enableAddToFavourite
                    enableUnQualifyLead={true}
                    checkDisable={checkBulkDesabled}
                    typeData={[
                      { id: "1", name: "owner" },
                      { id: "2", name: "seeker" },
                    ]}
                    enableSendToLeadPool={getEnableSendToLeadPoolWithPermissions()}
                    onFormTypeSelectChanged={onFormTypeSelectChanged}
                    onActionsButtonClicked={onActionsButtonClicked}
                    onActionButtonChanged={onActionButtonChanged}
                    closeAction={isCloseAction}
                    leadActiveAction={activeSelectedAction}
                  />
                )}
              </PermissionsComponent>
            </div>
            <QualificationFilter
              defaultValueis={defaultValueisQualification}
              onButtonClicked={onQualificationFilterButtonClicked}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
            <div className="section autocomplete-section">
              <PermissionsComponent
                permissionsList={
                  !isPropertyManagementView
                    ? Object.values(LeadsCAllCenterPermissions)
                    : Object.values(LeadsPermissions)
                }
                permissionsId={
                  !isPropertyManagementView
                    ? LeadsCAllCenterPermissions.ViewAndSearchInCallCenterLeads
                        .permissionsId
                    : LeadsPermissions.ViewandsearchinPropertyManagementLeads
                        .permissionsId
                }
              >
                <div className="d-flex-column px-2 w-100 p-relative">
                  <div className="w-100 p-relative">
                    <AutocompleteComponent
                      data={searchableFormFields.map((item) => ({
                        key: item.key,
                        title: item.title,
                      }))}
                      wrapperClasses="autocomplete-with-btn"
                      selectedValues={searchData}
                      parentTranslationPath="ContactsView"
                      displayLabel={displayedLabel}
                      disabledOptions={disabledOptions}
                      onChange={filterOnChange}
                      searchClicked={searchClicked}
                      chipsLabel={chipsLabel}
                      getOptionSelected={(option) =>
                        searchData.findIndex(
                          (item) =>
                            item.key === option.key &&
                            item.value === searchInputValue
                        ) !== -1
                      }
                      tagValues={searchData}
                      inputValue={searchInputValue}
                      onInputChange={inputValueChanged}
                      inputLabel="filter"
                      inputPlaceholder={t(`${translationPath}search-leads`)}
                    />
                  </div>
                  <div className="d-flex-v-center-h-between pl-5-reversed ">
                    {!pathName.includes("Leads-property-management") && (
                      <div className="container-display">
                        <div className="mr-1-reversed">
                          <SelectComponet
                            data={[
                              { id: 1, name: "owner" },
                              { id: 2, name: "seeker" },
                            ]}
                            emptyItem={{
                              value: 0,
                              text: "select-category",
                              isDisabled: false,
                            }}
                            value={activeFormType}
                            valueInput="id"
                            textInput="name"
                            onSelectChanged={(value) => {
                              changeActiveFormType(value);
                              if (
                                !(
                                  activeSelectedAction ===
                                  ActionsButtonsEnum[5].id
                                ) ||
                                value === 0
                              ) {
                                onActionButtonChanged(ActionsButtonsEnum[1].id);
                              } else if (
                                activeSelectedAction ===
                                ActionsButtonsEnum[5].id
                              ) {
                                if (value === 1) {
                                  changeActiveLeadType("Seller");
                                } else if (value === 2) {
                                  changeActiveLeadType("Buyer");
                                }
                              }
                            }}
                            wrapperClasses="w-auto"
                            themeClass="theme-default"
                            idRef="Select_Category"
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            translationPathForData={translationPath}
                          />
                        </div>
                        {activeFormType !== 0 && (
                          <div className="mr-1-reversed">
                            <SelectComponet
                              data={
                                activeFormType === 1
                                  ? [
                                      { id: "Seller", name: "seller" },
                                      { id: "Landlord", name: "landlord" },
                                    ]
                                  : [
                                      { id: "Buyer", name: "buyer" },
                                      { id: "Tenant", name: "tenant" },
                                    ]
                              }
                              emptyItem={{
                                value: 0,
                                text: "select-lead-type",
                                isDisabled: false,
                              }}
                              value={leadType}
                              valueInput="id"
                              textInput="name"
                              onSelectChanged={(value) => {
                                changeActiveLeadType(value);
                                setCheckedCards([]);
                                setCheckedCardsIds([]);
                                if (value === 0) {
                                  onActionButtonChanged(
                                    ActionsButtonsEnum[1].id
                                  );
                                }
                              }}
                              wrapperClasses="w-auto"
                              themeClass="theme-default"
                              idRef="Lead_Type"
                              parentTranslationPath={parentTranslationPath}
                              translationPath={translationPath}
                              translationPathForData={translationPath}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div className="mr-1-reversed">
                      <SelectComponet
                        data={[
                          { id: "Open", name: "open" },
                          { id: "Closed", name: "closed" },
                        ]}
                        emptyItem={{
                          value: 0,
                          text: "select-status",
                          isDisabled: false,
                        }}
                        value={status}
                        valueInput="id"
                        textInput="name"
                        onSelectChanged={(value) => {
                          changeStatusType(value);
                          onActionButtonChanged(ActionsButtonsEnum[1].id);
                        }}
                        wrapperClasses="w-auto"
                        themeClass="theme-default"
                        idRef="Lead_Status"
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                    <div className="w-100 mt-2">
                      <Inputs
                        value={searchedItem}
                        onKeyUp={(e) => searchHandler(e.target.value)}
                        idRef="activitiesSearchRef"
                        labelClasses="mt-4"
                        onInputChanged={(e) => setSearchedItem(e.target.value)}
                        inputPlaceholder={t(
                          `${translationPath}search-Mobile-Email-ID`
                        )}
                      />
                    </div>

                    <div className="w-100 customDatePicker">
                      <div className="date-type-select">
                        <SelectComponet
                          data={[
                            { key: 1, value: `${translationPath}created-date` },
                            {
                              key: 2,
                              value: `${translationPath}update-on-date`,
                            },
                            {
                              key: 3,
                              value: `${translationPath}last-assign-date`,
                            },
                          ]}
                          defaultValue={{
                            key: 1,
                            value: `${translationPath}created-date`,
                          }}
                          value={dateFilter.selectedDateType || 1}
                          valueInput="key"
                          textInput="value"
                          isDisabled={isLoading.allLeads}
                          onSelectChanged={(value) => {
                            setIsDatePickerChanged(true);
                            setDateFilter((f) => ({
                              ...f,
                              selectedDateType: value,
                            }));
                          }}
                          wrapperClasses="w-auto"
                          themeClass="theme-transparent"
                          idRef="Date_Select"
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <DateRangePickerComponent
                        onClearClicked={() => {
                          setDateFilter(dateRangeDefault);
                          setIsDatePickerChanged(true);
                        }}
                        onDialogClose={() => {
                          setIsDatePickerChanged(true);
                        }}
                        ranges={[dateFilter]}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(selectedDate) => {
                          setDateFilter((item) => ({
                            ...item,
                            startDate:
                              selectedDate.selection &&
                              selectedDate.selection.startDate,
                            endDate: new Date(
                              moment(
                                selectedDate.selection &&
                                  selectedDate.selection.endDate
                              ).endOf("day")
                            ),
                            key: "selection",
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="agentSection pl-5-reversed">
                    <div className="w-100 mr-1-reversed">
                      <AutocompleteComponent
                        idRef="ReferredByRef"
                        isLoading={isLoading.referred}
                        inputPlaceholder={t(`${translationPath}ReferredBy`)}
                        selectedValues={referred.by}
                        getOptionSelected={(option) =>
                          option.id ===
                            (orderFilter.ReferredByLeadTypeFilter &&
                              orderFilter.ReferredByLeadTypeFilter.id) || ""
                        }
                        data={(allReferred && allReferred.result) || []}
                        onInputChange={(e) => {
                          if (e && e.target && e.target.value)
                            getAllReferred(e.target.value || "");
                        }}
                        multiple={false}
                        displayLabel={(option) =>
                          (option && option.fullName) || ""
                        }
                        chipsLabel={(option) =>
                          (option && option.fullName) || ""
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              ReferredByLeadTypeFilter:
                                {
                                  id: (newValue && newValue.id) || "",
                                  fullName:
                                    (newValue && newValue.fullName) || "",
                                } || "",
                            })
                          );
                          setReferred((item) => ({
                            ...item,
                            by: newValue || "",
                          }));
                        }}
                      />
                    </div>
                    <div className="w-100 mr-1-reversed">
                      <AutocompleteComponent
                        idRef="ReferredToRef"
                        inputPlaceholder={t(`${translationPath}ReferredTo`)}
                        data={(allReferred && allReferred.result) || []}
                        selectedValues={referred.to}
                        getOptionSelected={(option) =>
                          option.id ===
                            (orderFilter.ReferredToLeadTypeFilter &&
                              orderFilter.ReferredToLeadTypeFilter.id) || ""
                        }
                        onInputChange={(e) => {
                          if (e && e.target && e.target.value)
                            getAllReferred(e.target.value || "");
                        }}
                        multiple={false}
                        displayLabel={(option) =>
                          (option && option.fullName) || ""
                        }
                        chipsLabel={(option) =>
                          (option && option.fullName) || ""
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              ReferredToLeadTypeFilter:
                                {
                                  id: (newValue && newValue.id) || "",
                                  fullName:
                                    (newValue && newValue.fullName) || "",
                                } || "",
                            })
                          );
                          setReferred((item) => ({
                            ...item,
                            to: newValue || null,
                          }));
                        }}
                      />
                    </div>
                    <div className="w-100 mr-0">
                      <AutocompleteComponent
                        idRef="MediaDetailsRef"
                        isLoading={isLoading.mediaDetails}
                        inputPlaceholder={t(`${translationPath}MediaDetails`)}
                        selectedValues={referred.mediaDetails}
                        getOptionSelected={(option) =>
                          option.lookupItemId ===
                            (orderFilter.MediaDetailsLeadTypeFilter &&
                              orderFilter.MediaDetailsLeadTypeFilter
                                .lookupItemId) || ""
                        }
                        data={allMediaName || []}
                        displayLabel={(option) =>
                          (option && option.lookupItemName) || ""
                        }
                        chipsLabel={(option) =>
                          (option && option.lookupItemName) || ""
                        }
                        multiple={false}
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              MediaDetailsLeadTypeFilter:
                                {
                                  lookupItemId:
                                    (newValue && newValue.lookupItemId) || "",
                                  lookupItemName:
                                    (newValue && newValue.lookupItemName) || "",
                                } || "",
                            })
                          );
                          setReferred((item) => ({
                            ...item,
                            mediaDetails: newValue || null,
                          }));
                        }}
                      />
                    </div>
                  </div>

                              <div className="d-flex-center pl-5-reversed w-50 mt-2">
                    <fieldset className="styled-fieldset d-flex mx-2">
                      <legend className="styled-legend">
                        {t(`${translationPath}Lead-score`)}
                      </legend>
                      <div className="w-50 mr-2">
                        <Inputs
                          idRef="priceFromRef"
                          labelClasses="Requierd-Color"
                          labelValue={t(`${translationPath}from`)}
                          value={state.fromScore || ' '}
                          type={"number"}
                          withNumberFormat
                          onKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              setState({
                                id: 'fromScore', value: value
                              })
                            }, 600);
                          }}
                          min={0}
                        />
                      </div>
                      <div className="w-50">
                        <Inputs
                          idRef="priceToRef"
                          labelClasses="Requierd-Color"
                          labelValue={t(`${translationPath}to`)}
                          value={state.toScore || ' '}
                          type={"number"}
                          withNumberFormat
                          onKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              setState({ id: 'toScore', value: value })
                            }, 600);
                          }}
                          min={0}
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="view-search-wrapper">
                  <ViewTypes
                    onTypeChanged={onTypeChanged}
                    initialActiveType={activeActionType}
                    className="mb-3"
                  />
                  <span className="advance-search">
                    <ButtonBase
                      onClick={onAdvanceSearchClick}
                      id="AdvanceSearch_bbt"
                      disabled={isAllFormFieldsLoading}
                      className={`btns theme-solid ${
                        isAdvanceSearchActive &&
                        !isAllFormFieldsLoading &&
                        "is-active-filter"
                      }`}
                    >
                      <span
                        className={`${
                          advanceSearchBtn
                            ? "mdi mdi-arrow-expand-left"
                            : "mdi mdi-arrow-expand-right"
                        } mr-1`}
                      />
                      {t(`${translationPath}advance-search`)}
                    </ButtonBase>
                  </span>
                  <ButtonBase
                    onClick={onClearedAllFiltersClick}
                    id="onClearedAllFiltersref"
                    disabled={isAllFormFieldsLoading}
                    className="btns theme-solid bg-danger clear-all-btn"
                  >
                    <span className="mdi mdi-filter-remove m-1" />
                    {t(`${translationPath}clear-filters`)}
                  </ButtonBase>
                  <ButtonBase
                    onClick={() => {
                      setisOpenFavouritDialog(true);
                    }}
                    id="cheitsButton"
                    className="btns theme-solid w-100 m-2"
                  >
                    <span className="mdi mdi-heart-box-outline ml-1"></span>
                    {t(`${translationPath}View-Favourite`)}
                  </ButtonBase>
                </div>
              </PermissionsComponent>
            </div>
          </div>

          <div className="d-flex px-2">
            <PermissionsComponent
              permissionsList={
                !isPropertyManagementView
                  ? Object.values(LeadsCAllCenterPermissions)
                  : Object.values(LeadsPermissions)
              }
              permissionsId={
                !isPropertyManagementView
                  ? LeadsCAllCenterPermissions.ViewAndSearchInCallCenterLeads
                      .permissionsId
                  : LeadsPermissions.ViewandsearchinPropertyManagementLeads
                      .permissionsId
              }
            >
              <span className="mx-2 mt-1">{t(`${translationPath}leads`)}</span>
              <span className="separator-v s-primary s-reverse s-h-25px mt-1" />
              <span className="px-2 d-flex">
                <span className="texts-large mt-1">
                  {t(`${translationPath}order-by`)}:
                </span>
                <div className="px-2">
                  <SelectComponet
                    idRef="filterByRef"
                    data={[
                      { id: "createdOn", filterBy: "created-on" },
                      { id: "updateOn", filterBy: "last-updated" },
                    ]}
                    value={selectedOrderBy.filterBy}
                    onSelectChanged={filterByChanged}
                    wrapperClasses="mb-3"
                    isRequired
                    valueInput="id"
                    textInput="filterBy"
                    emptyItem={{
                      value: null,
                      text: "select-filter-by",
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="px-2">
                  <SelectComponet
                    idRef="orderByRef"
                    data={[
                      { id: 1, orderBy: "ascending" },
                      { id: 2, orderBy: "descending" },
                    ]}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses="mb-3"
                    isRequired
                    valueInput="id"
                    textInput="orderBy"
                    emptyItem={{
                      value: null,
                      text: "select-sort-by",
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="mt-1">
                  <ButtonBase
                    className="btns theme-solid"
                    id="action_apply"
                    onClick={orderBySubmitted}
                    disabled={
                      !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                    }
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </PermissionsComponent>
          </div>
        </div>

        {activeActionType !== ViewTypesEnum.tableView.key && (
          <>
            <div className="body-section">
              <PermissionsComponent
                permissionsList={
                  !isPropertyManagementView
                    ? Object.values(LeadsCAllCenterPermissions)
                    : Object.values(LeadsPermissions)
                }
                permissionsId={
                  !isPropertyManagementView
                    ? LeadsCAllCenterPermissions.ViewAndSearchInCallCenterLeads
                        .permissionsId
                    : LeadsPermissions.ViewandsearchinPropertyManagementLeads
                        .permissionsId
                }
              >
                <LeadsCardsComponent
                  data={detailsLeadsList}
                  isExpanded={isExpanded}
                  onCardClicked={onCardClick}
                  folderIdValue={folderIdValue}
                  handleRemoveFavorite={handleRemoveFavorite}
                  onFooterActionsClicked={detailedCardSideActionClicked}
                  onActionClicked={detailedCardActionClicked}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  withCheckbox={
                    activeSelectedAction === ActionsButtonsEnum[5].id ||
                    activeSelectedAction === ActionsButtonsEnum[7].id ||
                    activeSelectedAction === ActionsButtonsEnum[6].id ||
                    activeSelectedAction === ActionsButtonsEnum[8].id ||
                    activeSelectedAction === ActionsButtonsEnum[23].id ||
                    activeSelectedAction === ActionsButtonsEnum[12].id ||
                    activeSelectedAction === ActionsButtonsEnum[13].id ||
                    activeSelectedAction === ActionsButtonsEnum[14].id ||
                    activeSelectedAction === ActionsButtonsEnum[26].id ||
                    activeSelectedAction === ActionsButtonsEnum[22].id
                  }
                  onCardCheckboxClick={cardCheckboxClicked}
                  activeCard={activeCard}
                  notExpandedMax={3}
                  selectedCards={checkedCards}
                  relodedata={reloadData}
                  isCheckBoxDisabled={
                    activeSelectedAction === "merge"
                      ? checkedCards.length >= 2
                      : false
                  }
                  activeSelectedAction={activeSelectedAction}
                  checkedCards={checkedCards}
                  displyOpenFileButton={displyOpenFileButton}
                  displyEditButton={displyEditButton}
                  displyMatchButton={displyMatchButton}
                  displyCloseLead={displyCloseLead}
                  checkIfSensitiveField={checkIfSensitiveField}
                />
              </PermissionsComponent>
            </div>
          </>
        )}

        {activeActionType === ViewTypesEnum.tableView.key && (
          <PermissionsComponent
            permissionsList={
              !isPropertyManagementView
                ? Object.values(LeadsCAllCenterPermissions)
                : Object.values(LeadsPermissions)
            }
            permissionsId={
              !isPropertyManagementView
                ? LeadsCAllCenterPermissions.ViewAndSearchInCallCenterLeads
                    .permissionsId
                : LeadsPermissions.ViewandsearchinPropertyManagementLeads
                    .permissionsId
            }
          >
            <LeadsLeaseTableComponent
              detailsLeadsList={detailsLeadsList}
              tableActionClicked={tableActionClicked}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              filter={filter}
              parentTranslationPath={parentTranslationPath}
              focusedRowChanged={focusedRowChanged}
              leadsTableFilter={leadsTableFilter}
              leadsFiltersCriteria={leadsFiltersCriteria}
              onFilterValuesChanged={onFilterValuesChanged}
              checkedCardsIds={checkedCardsIds}
              getIsSelected={getIsSelected}
              onSelectClicked={onSelectClicked}
              activeSelectedAction={activeSelectedAction}
              defaultActions={list}
              setCheckedCardsIds={setCheckedCardsIds}
              checkedCards={checkedCards}
              setCheckedCards={setCheckedCards}
              setOrderBy={setOrderBy}
              isClearFiltersClicked={isClearFiltersClicked}
              setIsClearFiltersClicked={setIsClearFiltersClicked}
              displyOpenFileButton={displyOpenFileButton}
              displyEditButton={displyEditButton}
              displyMatchButton={displyMatchButton}
              displyCloseLead={displyCloseLead}
              localStorageKey="LeadsFilter"
            />{" "}
          </PermissionsComponent>
        )}
        <LeadsActionDialogsComponent
          isOpen={isOpenContactsActionDialog}
          isOpenChanged={() => setisOpenContactsActionDialog(false)}
          actionEnum={detailedCardAction.actionEnum}
          item={detailedCardAction.item}
          translationPath={translationPath}
          parentTranslationPath="ContactsView"
        />
      </div>
      <LeadsImportDialog
        isOpen={isOpenImportDialog}
        isOpenChanged={() => setIsOpenImportDialog(false)}
      />
      {isOpenCloseLeadsDialog && (
        <CloseLeadsDialog
          isOpen={isOpenCloseLeadsDialog}
          setIsLoading={setIsLoading}
          onSave={(item) => {
            const closeLeadsBody = {
              leadsIds: checkedCardsIds,
              closeReasonId: item.closeReasonId,
              remarks: item.remarks,
            };
            closeLeads(closeLeadsBody);
            setIsOpenCloseLeadsDialog(false);
          }}
          onClose={() => {
            setIsOpenCloseLeadsDialog(false);
            resetAllValues();
          }}
        />
      )}
      {isOpenleadsReassignDialog && (
        <LeadsReassignDialog
          isOpen={isOpenleadsReassignDialog}
          leadType={
            checkedCards && checkedCards[0] && checkedCards[0].leadClass
          }
          isLoadingReassign={isLoadingReassign}
          setIsLoadingReassign={setIsLoadingReassign}
          onSave={(reassignItem) => {
            reassignHandler(reassignItem);
            setCheckedCards([]);
            setCheckedCardsIds([]);
          }}
          parentTranslationPath="ContactProfileManagementView"
          translationPath=""
          onClose={() => {
            setIsOpenleadsReassignDialog(false);
          }}
        />
      )}

      {isOpenCloneLeadsDialog && (
        <CloneLeadsDialog
          isOpen={isOpenCloneLeadsDialog}
          checkedCardsIds={checkedCardsIds}
          checkedCards={checkedCards}
          totalCloneLeads={checkedCardsIds && checkedCardsIds.length}
          setIsLoading={setIsLoading}
          onSave={(item) => {
            cloneLeads(item);
            setIsOpenCloneLeadsDialog(false);
          }}
          onClose={() => {
            setIsOpenCloneLeadsDialog(false);
            resetAllValues();
          }}
        />
      )}
      {isOpenSendToRotationDialog && (
        <SendToRoationDialog
          isOpen={isOpenSendToRotationDialog}
          isForOwnerLeads={isForOwnerLeads}
          checkedCardsIds={checkedCardsIds}
          onClose={() => {
            setIsOpenSendToRotationDialog(false);
            resetAllValues();
            resetAllValues();
          }}
        />
      )}
      {addActivity && (
        <ActivitiesManagementDialog
          open={addActivity}
          onSave={() => {
            setAddActivity(false);
          }}
          close={() => {
            setAddActivity(false);
          }}
          actionItemId={activeItem?.id}
          translationPath={""}
          parentTranslationPath={"LeadsProfileManagementView"}
          leadClass={activeItem?.leadClass}
          leadStageId={activeItem?.lead_stage?.lookupItemId}
        />
      )}
      {UnqualifiedLeadsDialogDialog && (
        <UnqualifiedLeadsDialog
          isOpen={UnqualifiedLeadsDialogDialog}
          checkedCardsIds={checkedCardsIds}
          checkedCards={checkedCards}
          totalCloneLeads={checkedCardsIds && checkedCardsIds.length}
          onSave={(item) => {
            setUnqualifiedLeadsDialogDialog(false);
            setCheckedCards([]);
            setCheckedCardsIds([]);
            resetAllValues();
          }}
          onClose={() => {
            setUnqualifiedLeadsDialogDialog(false);
            resetAllValues();
          }}
        />
      )}
      {isOpenQualifyLeadDialog && (
        <QualifyLeadDialog
          isOpen={isOpenQualifyLeadDialog}
          checkedLeadsIds={checkedCardsIds}
          onClose={() => {
            setIsOpenQualifyLeadDialog(false);
            resetAllValues();
          }}
        />
      )}
      {sendToLeadsPoolDialog && (
        <SendToLeadsPool
          isOpen
          selectedLeads={checkedCardsIds}
          reloadData={reloadData}
          onClose={() => setSendToLeadsPoolDialog(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
              {isOpenFavouriteDialog && (
            <FavoriteDFM
              isOpen={isOpenFavouriteDialog}
              closeHandler={() => {setisOpenFavouritDialog(false);setActiveItem(null);}}
              parentTranslationPath={'ShareUnit'}
              translationPath=''
              FavoriteType='LEAD'
              AddTOlistView={activeSelectedAction === ActionsButtonsEnum[26].id || false}
              checkedDetailed={checkedCards && checkedCards}
              Rest={() => {
                setActiveItem(null);
                setCheckedCards([]);
                onActionButtonChanged(ActionsButtonsEnum[1].id);
               }}
               checkDesabled={(checkedCards && checkedCards.length > 0)}
               setfolderIdValue={(item)=>{setfolderIdValue(item)}}
               setFilterfolderId={(item)=>{SearchHandlerComponent("FavoriteFolderId", `Favorite Folder  ${item.name ||''} ID  `  , item.favoriteFolderId, 300)}}
               GetAllDataInSearch={()=>{}}
               reloadData={reloadData}
            />)}
      {isConsentActionDialogOpen &&
        <ConsentActionsDialog
          open={isConsentActionDialogOpen}
          communicationActionHandler={() => {
            setIsConsentActionDialogOpen(false);

            const contactItem = detailedCardAction?.item;
            const actionEnum = detailedCardAction?.actionEnum;
            contactActionsHandler(actionEnum, contactItem);
          }}
          onClose={() => {
            setIsConsentActionDialogOpen(false);
            closeSideMenu();
          }}
          activeRecipient={detailedCardAction?.item}
          recipientType={"Lead"}
        />
      }
      {isWorkingHoursDialogOpen &&
        <WorkingHoursConfirmDialog
          open={isWorkingHoursDialogOpen}
          communicationActionHandler={() => {
            setIsWorkingHoursDialogOpen(false);

            const contactItem = detailedCardAction?.item;
            const actionEnum = detailedCardAction?.actionEnum;
            contactActionsHandler(actionEnum, contactItem);
          }}
          onClose={() => {
            setIsWorkingHoursDialogOpen(false)
            closeSideMenu();
          }}
          activeRecipient={detailedCardAction?.item}
          recipientType={"Lead"}
        />
      }
    </div>
  );
};
