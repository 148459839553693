import React, { useState } from "react";
import moment from "moment";
import { Box, Avatar, Typography } from "@material-ui/core";
import {
  CustomCard,
  CustomBadge,
  CustomButton,
  LeadTypeBadges,
} from "../../../../../../Components";
import {
  InfoSection,
  InfoItem,
  ContactItemList,
} from "../../../../../../Components/V2/ContactProfile/UI";
import { CopyToClipboardComponents } from "../../../../../../ReusableComponents/UtilityComponents";
import {
  useIsDesktop,
  useSelectedTheme,
  useTranslate,
} from "../../../../../../Hooks";
import { getDownloadableLink } from "../../../../../../Helper";

// Styles
import useStyles from "./styles";

// Icons
import {
  ActivityIcon,
  CopyIcon,
  DropdownIcon,
  EmailIcon,
  ListIcon,
  PhoneIcon,
  SMSIcon,
  WhatsappIcon,
} from "../../../../../../assets/icons";

function ContactProfileOverview({ details }) {
  const styles = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  const { isDesktop } = useIsDesktop();

  const {
    theme: { palette },
  } = useSelectedTheme();

  const { translate } = useTranslate("NewContactsView");

  return (
    <CustomCard borderRadius="xl" boxShadow="xs" borderColor="secondary">
      <Box className={styles.detailsBg} />
      <Box className={styles.detailsContent}>
        <Box className={styles.avatarWrapper}>
          <Avatar
            className={
              details?.imagePath ? styles.avatar : styles.defaultAvatar
            }
            src={
              details?.imagePath
                ? getDownloadableLink(details?.imagePath)
                : details?.contact_type_id === 2 // "CORPORATE"
                ? "/images/contacts/CorporateAvatar.svg"
                : details?.gender?.lookupItemName === "Male"
                ? "/images/contacts/MaleAvatar.svg"
                : "/images/contacts/FemaleAvatar.svg"
            }
          />
        </Box>
        <Box className={styles.contactDetails}>
          <Box className={styles.nameWrapper}>
            {details?.title?.lookupItemName && (
              <Typography
                className={styles.prefix}
                style={{ order: false ? 1 : "unset" }}
                variant="body1"
              >
                {details?.title?.lookupItemName}
              </Typography>
            )}
            <Typography className={styles.fullName} variant="h5">
              {details?.company_name
                ? `${details?.company_name}`
                : `${details?.first_name || ""} ${details?.last_name || ""}`}
            </Typography>
          </Box>

          <Box className={styles.specificationWrapper}>
            <label className={styles.contactTypeLabel}>
              {details?.contact_type_id === 2
                ? translate("CORPORATE")
                : translate("INDIVIDUAL")}
            </label>
            {(details?.contact_class?.lookupItemName === "VIP" ||
              details?.company_class?.lookupItemName === "VIP") && (
              <CustomBadge
                Style={{
                  padding: "0 2px",
                  borderRadius: "2px",
                  lineHeight: "20px",
                }}
                label={translate("VIP")}
                BackgroundColor={palette.utility.brand_300}
                BorderColor={palette.utility.brand_300}
                Color={"#FFF"}
              />
            )}

            {(details?.contact_class?.lookupItemName === "VVIP" ||
              details?.company_class?.lookupItemName === "VVIP") && (
              <CustomBadge
                Style={{
                  padding: "0 2px",
                  borderRadius: "2px",
                  lineHeight: "20px",
                }}
                label={translate("VVIP")}
                BackgroundColor={palette.utility.brand_600}
                BorderColor={palette.utility.brand_600}
                Color={"#FFF"}
              />
            )}

            {details?.isBulkUpload && (
              <span className={styles.isBulk}>{translate("BULK")}</span>
            )}

            <CopyToClipboardComponents
              data={details.contactId || details?.id} // copy
              childrenData={`#${details.contactId || details?.id}`} // render
              CustomizationClassName={styles.copyToClipboardWrapper}
              isNewDesign
              hideToastify
            >
              <CopyIcon
                width="20"
                height="20"
                fill={palette.button.tertiary_fg}
                style={{ marginInlineStart: "8px" }}
              />
            </CopyToClipboardComponents>

            <CustomBadge
              label={details?.data_completed + "%" || "0%"}
              SizeVariant={"small"}
              BackgroundColor={
                palette.utility[
                  details?.data_completed >= 50 ? "brand_50" : "error_50"
                ]
              }
              BorderColor={
                palette.utility[
                  details?.data_completed >= 50 ? "brand_200" : "error_200"
                ]
              }
              Color={
                palette.utility[
                  details?.data_completed >= 50 ? "brand_700" : "error_700"
                ]
              }
            />
          </Box>

          {details?.leadTypes && (
            <LeadTypeBadges leadTypesList={details.leadTypes} />
          )}

          <Box className={styles.actionsWrapper}>
            <CustomButton
              boxShadow="xs"
              size="md"
              variant="contained"
              color="primary"
              startIcon={
                <ActivityIcon
                  width="20"
                  height="20"
                  fill={palette.button.primary_fg}
                />
              }
            >
              {translate("ADD_ACTIVITY")}
            </CustomButton>
            <CustomButton
              boxShadow="xs"
              size="md"
              variant="outlined"
              color="secondaryColor"
              startIcon={
                <ListIcon
                  width="20"
                  height="20"
                  fill={palette.button.secondaryColor_fg}
                />
              }
            >
              {translate("ADD_TASK")}
            </CustomButton>
          </Box>
        </Box>
        {(isExpanded || isDesktop) && (
          <>
            <InfoSection title={translate("CONTACT_INFORMATION")}>
              <InfoItem
                label={translate("NATIONALITY")}
                value={
                  details?.details?.find((item) => item.title === "nationality")
                    ?.value
                }
              />
              <InfoItem
                label={translate("LANGUAGE")}
                value={details?.language?.lookupItemName || "N/A"}
              />
              <InfoItem
                label={translate("COUNTRY")}
                value={details?.country?.lookupItemName || "N/A"}
                countryCode={details?.country?.lookupItemCode}
              />
              <InfoItem
                label={translate("CITY")}
                value={details?.city?.lookupItemName || "N/A"}
              />
              <InfoItem
                label={translate("COMMUNITY")}
                value={details?.community?.lookupItemName || "N/A"}
              />
              <InfoItem
                label={translate("SUB_COMMUNITY")}
                value={
                  details?.allDetails?.["Residence Address"]?.find(
                    (details) => details?.title === "sub_community"
                  )?.value
                }
              />
              <InfoItem
                label={translate("DATE_OF_BIRTH")}
                value={
                  details?.date_of_birth
                    ? moment(details?.date_of_birth).format("DD-MM-YYYY")
                    : "N/A"
                }
              />
              <InfoItem
                label={translate("CREATED_BY")}
                value={details?.createdBy}
              />
              <InfoItem
                label={translate("CREATED_DATE")}
                value={moment(details?.createdOn).format("DD-MM-YYYY")}
              />
            </InfoSection>

            <InfoSection title={translate("CONTACTS")}>
              <ContactItemList
                labelIcon={
                  <Box className={styles.iconWrapper}>
                    <PhoneIcon
                      width="20"
                      height="20"
                      fill={palette.utility.utility_gray_500}
                    />
                  </Box>
                }
                label={translate("PHONE")}
                values={[
                  details?.mobile?.phone ||
                    details?.landline_number?.phone ||
                    "",
                  ...(details?.other_contact_mobile_no?.others ||
                    details?.landline_number?.others ||
                    []),
                ].filter((item) => !!item)}
              />
              <ContactItemList
                labelIcon={
                  <Box className={styles.iconWrapper}>
                    <EmailIcon
                      width="20"
                      height="20"
                      fill={palette.utility.utility_gray_500}
                    />
                  </Box>
                }
                label={translate("EMAIL")}
                values={[
                  details?.email_address?.email ||
                    details?.general_email?.email ||
                    "",
                  ...(details?.general_email?.others ||
                    details?.email_address?.others ||
                    []),
                ].filter((item) => !!item)}
              />
              <ContactItemList
                labelIcon={
                  <Box className={styles.iconWrapper}>
                    <WhatsappIcon
                      width="20"
                      height="20"
                      fill={palette.utility.utility_gray_500}
                    />
                  </Box>
                }
                label={translate("WHATSAPP")}
                values={[
                  details?.whatsapp_mobile?.phone || "",
                  ...(details?.whatsapp_mobile?.others || []),
                ].filter((item) => !!item)}
              />
              <ContactItemList
                labelIcon={
                  <Box className={styles.iconWrapper}>
                    <SMSIcon
                      width="20"
                      height="20"
                      fill={palette.utility.utility_gray_500}
                    />
                  </Box>
                }
                label={translate("SMS")}
                values={[
                  details?.mobile?.phone ||
                    details?.landline_number?.phone ||
                    "",
                  ...(details?.other_contact_mobile_no?.others ||
                    details?.landline_number?.others ||
                    []),
                ].filter((item) => !!item)}
              />
            </InfoSection>
          </>
        )}
        {!isDesktop && (
          <Box className={styles.showMoreWrapper}>
            <CustomButton
              size="lg"
              variant="text"
              color="tertiary"
              onClick={() => setIsExpanded((prev) => !prev)}
              endIcon={
                <DropdownIcon
                  width="20"
                  height="20"
                  fill={palette.button.tertiary_fg}
                  style={{ transform: isExpanded ? "rotate(180deg)" : "none" }}
                />
              }
            >
              {isExpanded ? "Show Less" : "Show More"}
            </CustomButton>
          </Box>
        )}
      </Box>
    </CustomCard>
  );
}

export default ContactProfileOverview;
