import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    detailsBg: {
      background: theme.palette.utility.brand_200,
      height: '72px',
      marginBottom: '53px',
    },
    detailsContent: {
      padding: '0px 24px',
      marginTop: "-106px",
      top: '20px',
      width: '100%',
    },
    iconWrapper: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      width: "24px",
      height: "24px",
      background: theme.palette.utility.utility_gray_200,
      borderRadius: theme.borderRadius[6],
    },
    isBulk: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },

    // avatar styles
    avatarWrapper: {
      position: "relative",
      alignItems: "center",
      backgroundColor: "#FFF",
      borderRadius: theme.borderRadius[10],
      boxShadow: theme.shadows[4],
      display: "flex",
      height: "104px",
      width: "104px",
      justifyContent: "center",
    },
    defaultAvatar: {
      backgroundColor: "#F2F4F7", // avatar background
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "96px",
      width: "96px",

      "& img": {
        height: "48px",
        width: "48px",
      },
    },
    avatar: {
      backgroundColor: "#F2F4F7", // avatar background
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "96px",
      width: "96px",
      '& img': {
        objectFit: "contain",
      }
    },
    contactDetails: {
      marginTop: "16px",
    },
    nameWrapper: {
      display: 'flex',
      alignItems: 'baseline',
      gap: '8px',
      marginBottom: '8px',
    },
    prefix: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '30px',
      color: theme.palette.text.primary,
      direction: 'ltr',
    },
    fullName: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
      color: theme.palette.text.primary,
    },

    specificationWrapper: {
      marginBottom: '8px',
      display: 'flex',
      gap: '8px',
      alignItems: "baseline",
      flexWrap: 'wrap',
    },
    contactTypeLabel: {
      fontWeight: 400,
      fontSize: "16px",
      color: theme.palette.text.tertiary,
    },
    copyToClipboardWrapper: {
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.button.tertiary_fg,
      cursor: 'pointer',
      padding: 0,
      margin: '0px !important',
      borderRadius: theme.borderRadius[4],
      direction: 'ltr',
      marginInlineStart: '4px !important',

      '&:hover': {
        color: theme.palette.button.tertiary_fg_hover,
        '& svg, & svg path': {
          fill: theme.palette.button.tertiary_fg_hover,
        },
      },
    },

    actionsWrapper: {
      display: 'flex',
      gap: '12px',
      margin: '24px 0',
    },
    showMoreWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: "24px",
    }
  }
});
