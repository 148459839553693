import React, { useCallback, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { OverviewCard } from '../OverviewCard';
import ContactOverviewUnitCard from './UI/ContactOverviewUnitCard';
import { useVerticalNav } from '../../../../../../Contexts/VerticalNavContext';
import { useIsDesktop } from '../../../../../../Hooks';

// Styles
import useStyles from './styles';
import { GetLastUnitsWithTotalCounts } from '../../../../../../Services';
import ContactUnitListCardSkeleton from '../../../../../../Components/V2/SkeletonLoader/ContactUnitListCardSkeleton/ContactUnitListCardSkeleton';

function ContactOverviewSummary({ setTabValue }) {
  const [contactUnitsData, setContactUnitsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isExpanded } = useVerticalNav();

  const { isDesktop } = useIsDesktop();

  const styles = useStyles();

  const fetchContactUnits = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await GetLastUnitsWithTotalCounts(1981671);

      if (!(response && response.status && response.status !== 200)) {
        setContactUnitsData({
          result: (response && response.units) || 0,
          totalCount: (response && response.totalUnits) || 0,
        });
      } else {
        setContactUnitsData({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error('Failed to fetch contacts:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchContactUnits();
  }, []);
  return (
    <Box
      className={clsx(styles.overviewContainer, {
        [styles.overviewContainerExpanded]: isExpanded && isDesktop,
      })}
    >
      <Box className={styles.mainContentBox}>
        <OverviewCard title='User activity timeline' label='48 Activities'>
          <div>User activity timeline</div>
        </OverviewCard>
        <OverviewCard title='Transactions History' label='48 transactions'>
          <div>User activity timeline</div>
        </OverviewCard>
        <OverviewCard title='Comments' hideActionButton>
          <div>User activity timeline</div>
        </OverviewCard>
      </Box>
      <Box
        className={clsx(styles.sideContentBox, {
          [styles.sideContentBoxExpanded]: isExpanded && isDesktop,
        })}
      >
        <OverviewCard title='Leads' label={48}>
          <div>Leads card content</div>
        </OverviewCard>
        {isLoading ? (
          <ContactUnitListCardSkeleton numberOfCards={3} />
        ) : (
          <OverviewCard
            title='Units'
            label={contactUnitsData?.totalCount}
            onActionClick={() => setTabValue}
          >
            <ContactOverviewUnitCard contactUnitsData={contactUnitsData} />
          </OverviewCard>
        )}
      </Box>
    </Box>
  );
}

export default ContactOverviewSummary;
