import React, { useState, useCallback, useEffect } from "react";
import { PropTypes } from "prop-types";
import { ButtonBase } from "@material-ui/core";
import { TableActions } from "../../../../Enums/TableActions.Enum";
import { Tables } from "../../../../Components";
import { ShareUnitTableHeaderData } from "./ShareUnitTableHeaderData";
import { TableFilterTypesEnum } from "../../../../Enums";
const translationPath = "";
export const ShareUnitTableComponent = ({
  filter,
  data,
  openFile,
  parentTranslationPath,
  translationPath,
  setIsOpenReassign,
  setActiveItem,
  activeItem,
  checkedDetailedCards,
  withCheckbox,
  getIsSelected,
  onSelectClicked,
  setOrderBy,
  onFilterValuesChanged,
  shareTableFilter,
  isClearFiltersClicked,
  setIsClearFiltersClicked
}) => {

 
  // const [activeItem, setActiveItem] = useState(null);
  const [TableFilterData, setTableFilterData] = useState([]);
  const [currentActions, setCurrentActions] = useState(() => []);

  const pathName = window.location.pathname.split("/home/")[1].split("/QA")[0];
  const [sortBy, setSortBy] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.preventDefault();
      setActiveItem(item);
      if (actionEnum === TableActions.openFile.key) {
        setIsOpenReassign(true);
      }
    },
    [pathName]
  );
  const focusedRowChanged = (activeRow) => {
    const item = data && data[activeRow];
    if (!item) return;
    setCurrentActions(getTableActionsWithPermissions(item.reassignIsAble));
  };
  useEffect(() => {
    if (sortBy)
      setOrderBy((item) => ({ ...item, filterBy: sortBy.filterBy && sortBy.filterBy[0].toLowerCase() + sortBy.filterBy.slice(1), orderBy: sortBy.orderBy, fieldType: sortBy.fieldType }));
  }, [sortBy]);

  useEffect(() => {
    setTableFilterData(
      ShareUnitTableHeaderData.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType: (column.isDate && TableFilterTypesEnum.datePicker.key) || (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) || TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        isHidden: column.isHidden,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input
      }))
    );
  }, [tableColumns]);
  const getTableActionsWithPermissions = (reassignIsAble) => {
    const list = [];
    if (reassignIsAble) {
      list.push({
        enum: TableActions.openFile.key,
      });
    } else {
      list.push({
        enum: TableActions.openFile.key,
      });
    }
    return list;
  };
  return (
    <div>
      <Tables
        data={data || []}
        headerData={ShareUnitTableHeaderData}
        selectAllOptions={
          (withCheckbox &&
            data &&
            data.length && {
            selectedRows: checkedDetailedCards,
            getIsSelected,
            disabledRows: [],
            onSelectClicked,
          }) ||
          undefined
        }
        focusedRowChanged={focusedRowChanged}
        defaultActions={[
          {
            enum: TableActions.openFile.key,
          },
        ]}
        actionsOptions={{
          actions: currentActions,
          classes: "",
          isDisabled: false,
          onActionClicked: tableActionClicked,
        }}
        itemsPerPage={filter.pageSize}
        isSellectAllDisabled
        activePage={filter.pageIndex}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        onFilterValuesChanged={onFilterValuesChanged}
        filterData={TableFilterData}
        isWithFilter
        setSortBy={setSortBy}
        filterValues={shareTableFilter}
        setIsClearFiltersClicked={setIsClearFiltersClicked}
         isClearFiltersClicked={isClearFiltersClicked}
      />
    </div>
  );
};

ShareUnitTableComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
};
