import React from 'react';

export const ShareUnitTableHeaderData = [
        {
          id: 1,
          label: "unitId",
          input: "unitId",
          isSortable :true,
          isHiddenFilter: false,
          isDefaultFilterColumn: true,
          fieldType: 'text' ,
        },
        {
          id: 2,
          label: "shareUnitId",
          input: "shareUnitId",
          isSortable :true,
          fieldType: 'text' ,
        },
        {
          id: 3,
          label: "property_name",
          input: "property_name",
          isSortable :true,
          fieldType: 'text'
        },
        {
          id: 4,
          label: "operation_type",
          input: "operation_type",
          isSortable :true,
          fieldType: 'text' ,
        },
        {
          id: 5,
          label: "unit_ref_no",
          input: "unit_ref_no",
          isSortable :true,
          fieldType: 'text' ,
        },
        {
          id: 6,
          label: "furnished",
          input: "furnished",
          isSortable :true ,
          fieldType: 'text' ,
        },
        {
          id: 7,
          label: "unit_type",
          input: "unit_type",
          isSortable :true ,
          fieldType: 'text' ,
        },
        {
          id: 8,
          label: "Sharing-date",
          input: "sharedOn",
          isSortable :true,
          isDate: true,
          fieldType: 'text' ,
        },
        {
          id: 9,
          label: "Sharing-by",
          input: "sharedBy",
          isSortable :true ,
          isSortable :true,
          fieldType: 'text',
        }
]